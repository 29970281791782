import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartStudiesComponent } from './chart-studies.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
    ],
    declarations: [
        ChartStudiesComponent,
    ],
    exports: [
        ChartStudiesComponent,
    ],
    providers: [
    ],
})
export class ChartStudiesModule { }