import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
    Component,
    Input,
} from '@angular/core';
import { ConfirmOptions } from './confirm-options';

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html'
})
export class ConfirmModalComponent {
    @Input() options: ConfirmOptions;

    constructor(
        private activeModal: NgbActiveModal
    ) {
        // Nothing to do
    }

    // Handle events
    yes() {
        this.activeModal.close('yes');
    }

    no() {
        this.activeModal.dismiss('no');
    }

    extra() {
        this.activeModal.close('extra');
    }
}
