import { Facet } from '../facets-models';
import { UpdateFacetModalComponent } from './update-facet-modal.component';

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class UpdateFacetModalService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }


    openUpdateFacetModal(facet: Facet): Promise<Facet> {

        // Open modal
        const modalRef = this.modalService.open(
            UpdateFacetModalComponent, this.modalOptions
        );
		let component = modalRef.componentInstance as UpdateFacetModalComponent;
		component.facet = facet;

        return modalRef.result.then(
            (result) => {
                return facet;
            },
            (reason) => {
                return reason;
            });
    }
}
