import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClimbRolesTableComponent } from './climb-roles-table.component';
import { UpdateClimbRoleModalComponent } from './modals/update-climb-role-modal.component';
import { UpdateClimbRoleModalService } from './modals/update-climb-role-modal.service';
import { CcaCommonModule } from '../common/cca-common.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        NgbModule,
        CcaCommonModule
    ],
    declarations: [
		ClimbRolesTableComponent,
		UpdateClimbRoleModalComponent
    ],
	exports: [
		ClimbRolesTableComponent,
		UpdateClimbRoleModalComponent
    ],
	providers: [
        UpdateClimbRoleModalService
    ]
})
export class ClimbRolesModule { }
