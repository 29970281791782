import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";

import { Job, JobDetail } from './job';

@Component({
    selector: 'job',
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit {
    @ViewChild('resultModal') resultModal: TemplateRef<any>;

    targetGroup: string;
    recentJobs: Job[];
    job: Job;
    details: JobDetail[];
    status: string;
    clicked: boolean = true;

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
    ) {
        // Do nothing
    }

  ngOnInit() {
    this.refreshTargetGroup();
    this.refreshRecentJobs();
  }

  refreshTargetGroup() {
      this.http.get('/api/Job/TargetGroup', { responseType: 'text' }).subscribe((result: string) => {
          this.targetGroup = result;
          console.log('foo: ' + this.targetGroup);
    });
  }

    fetchRecentJobs(): Observable<Job[]> {
        return this.http.get('/api/Job/Recent').pipe(
            tap((result: Job[]) => {
                this.recentJobs = result;
            })
        );
    }

    fetchDetails(): Observable<JobDetail[]> {
        return this.http.get(`/api/Job/Details/${this.job.name}`).pipe(
            tap((result: JobDetail[]) => {
                this.details = result;
            })
        );
    }

    refreshRecentJobs() {
        this.clicked = true;
        this.fetchRecentJobs().pipe(finalize(() => { this.clicked = false; })).subscribe();
    }

    refreshDetails() {
        this.fetchDetails().subscribe();
    }

    createJob(job: Job) {
        // Trim job name to prevent running jobs with names that differ only in leading/trailing whitespace
        job.name = job.name.trim();
        // Trim command text to prevent parsing issues
        job.command = job.command.trim();

        this.http.post<Job>('/api/Job/Create', job).subscribe(result => {
            if (result && !result.errorMessage) {
                this.status = 'Your new job has been created.\nIt may take a few moments for it to appear in this list.';
            } else {
                this.status = result.errorMessage;
            }

            this.openResultModal(this.resultModal);
        });
    }

    fetchCommand(): Observable<Job> {
        return this.http.get(`/api/Job/Command/${this.job.name}`).pipe(
            tap((result: Job) => {
                this.job = result;
            })
        );
    }

    refreshCommand() {
        this.fetchCommand().subscribe();
    }

    openNewModal(modal): Promise<any> {
        this.job = new Job();
        const ref = this.modalService.open(modal, {
            size: 'xl',
            scrollable: true,
            backdrop: 'static'
        });

        return ref.result.then(
            (_: any) => {
                this.createJob(this.job);
            },
            () => {
                // Cancelled. Do nothing
            }
        );
    }

    openDetailsModal(modal, job: Job) {
        this.job = job;
        this.details = null;

        this.fetchDetails().subscribe(() => {
            this.modalService.open(modal, {
                size: 'xl',
                scrollable: true,
                backdrop: 'static'
            });
        });
    }

    openCommandModal(modal, job: Job) {
        this.job = job;
        this.refreshCommand();

        this.modalService.open(modal, {
            size: 'xl',
            scrollable: true,
            backdrop: 'static'
        });
    }

    openResultModal(modal: TemplateRef<any>) {
        this.modalService.open(modal, {
            size: 'md',
            scrollable: false,
            backdrop: 'static'
        });
    }
}
