<div class="d-flex">
    <div class="p-2">
        <h2>
            Workgroup Details
        </h2>

        <table class='table' *ngIf="workgroup">
            <tbody>
                <tr>
                    <th>Workgroup Name:</th>
                    <td>{{workgroup.name}}</td>
                </tr>
                <tr>
                    <th>Key:</th>
                    <td>{{workgroup.key}}</td>
                </tr>
                <tr>
                    <th>Status:</th>
                    <td>{{workgroup.workgroupStatusType}}</td>
                </tr>
                <tr>
                    <th>Server:</th>
                    <td>{{workgroup.serverName}}</td>
                </tr>
                <tr>
                    <th>Customer:</th>
                    <td>{{workgroup.customerName}}</td>
                </tr>
                <tr>
                    <th>Administrator Registration Code:</th>
                    <td>{{workgroup.registrationCode}}</td>
                </tr>
                <tr>
                    <th>Time Zone:</th>
                    <td>
                        <span class="select-span">
                            <select *ngIf="workgroup && timeZones.length"
                                    id="timeZoneName"
                                    name="timeZoneName"
                                    class="form-control"
                                    [(ngModel)]="workgroup.timeZoneName"
                                    (ngModelChange)="updateWorkgroup()">
                                <option [ngValue]="null">Select time zone</option>
                                <option *ngFor="let tz of timeZones"
                                        [ngValue]="tz.id">
                                    {{tz.displayName}}
                                </option>
                            </select>
                        </span>
                    </td>
                </tr>
                <tr>
                    <th>Can Edit Inputs/Outputs For Used Tasks:</th>
                    <td>
                        <input *ngIf="workgroup"
                               type="checkbox"
                               name="canEditInputsCheckbox"
                               [(ngModel)]="workgroup.canEditInputsAndOutputsForUsedTasks"
                               (ngModelChange)="updateWorkgroup()" />
                    </td>
                </tr>
                <tr>
                    <th>Enforce SSO Sign-in:</th>
                    <td>
                        <input *ngIf="workgroup"
                               type="checkbox"
                               name="enforceSSOCheckbox"
                               [(ngModel)]="workgroup.enforceSSO"
                               (ngModelChange)="updateWorkgroup()" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<mat-tab-group>
    <mat-tab label="Roles">
        <climb-roles-table *ngIf="workgroup"
                           [workgroupKey]="workgroup.key">
        </climb-roles-table>
    </mat-tab>
    <mat-tab label="Facets">
        <facets-table *ngIf="workgroup"
                      [workgroupKey]="workgroup.key">
        </facets-table>
    </mat-tab>
    <mat-tab label="Workgroup Feature Flags">
        <workgroup-feature-flags-table *ngIf="workgroup"
                                       [workgroupKey]="workgroup.key">
        </workgroup-feature-flags-table>
    </mat-tab>
    <mat-tab label="Analytics">
        <app-chart-datecount *ngIf='workgroup' 
                        [workgroupKey]="workgroup.key"></app-chart-datecount>
    </mat-tab>
</mat-tab-group>