<div class="main-nav">
  <div class="navbar navbar-expand-sm">
    <div class="navbar-header">
      <button type="button" class="navbar-toggler sr-only" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" [routerLink]="['/home']"><b>Climb Management</b></a>
    </div>
    <div class="navbar-collapse collapse">
        <ul class="navbar-nav">
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link home-link" [routerLink]="['/home']">
                    <fa-icon icon="home"></fa-icon> Home
                </a>
            </li>
            <li class="nav-section">System</li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link" [routerLink]="['/job']">
                    <fa-icon icon="tasks"></fa-icon> Jobs
                </a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link" [routerLink]="['/messaging']">
                    <fa-icon icon="envelope"></fa-icon> Messaging
                </a>
            </li>
            <li class="nav-section">CX</li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link" [routerLink]="['/user']">
                    <fa-icon icon="user"></fa-icon> Users
                </a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link" [routerLink]="['/workgroup']">
                    <fa-icon icon="th-list"></fa-icon> Workgroups
                </a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link" [routerLink]="['/usage-reports']">
                    <fa-icon icon="clipboard-list"></fa-icon> Usage Reports
                </a>
            </li>
            <li class="nav-item" [routerLinkActive]="['link-active']">
                <a class="nav-link" [routerLink]="['/feature-flags']">
                    <fa-icon icon="flag"></fa-icon> Feature Flags
                </a>
            </li>
        </ul>
    </div>
  </div>
</div>
