import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { NavMenuComponent } from './navmenu.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserModule,
        HttpClientModule,
        NgbModule,
        FontAwesomeModule,
        RouterModule
    ],
    declarations: [
        NavMenuComponent
    ],
    exports: [
        NavMenuComponent
    ],
    providers: [
    ]
})
export class NavMenuModule { }
