<div class="modal-header">
    <h3 class="modal-title">
        {{options.title}}
    </h3>
</div>

<div class="modal-body">
    <p *ngIf="!options.isHtml">
        {{options.message}}
    </p>
    <p *ngIf="options.isHtml" [innerHTML]="options.message">
    </p>

    <ng-container *ngIf="options.details && !options.titleDetails">
        <p *ngFor="let detail of options.details">
            {{detail}}
        </p>
    </ng-container>

    <ng-container *ngIf="options.details && options.titleDetails">
        <p>
            <span>
                {{options.titleDetails}}
            </span>
            <span *ngFor="let detail of options.details">
                {{detail}}
            </span>
        </p>
    </ng-container>
</div>

<div class="modal-footer">
    <button *ngIf="options.extraButton"
            type="button"
            class="btn btn-lg btn-fw-xwide"
            [ngClass]="options.extraButtonClass"
            (click)="extra()">
        {{options.extraButtonText}}
    </button>
    <button type="button"
            class="btn btn-lg btn-fw-xwide"
            [ngClass]="options.yesButtonClass"
            (click)="yes()">
        {{options.yesButtonText}}
    </button>
    <button *ngIf="!options.onlyYes"
            type="button"
            class="btn btn-lg btn-fw-xwide btn-default"
            (click)="no()">
        {{options.noButtonText}}
    </button>
</div>