import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegistrationCodeWrapper } from '../climb-roles/climb-roles-models';

@Injectable({
    providedIn: 'root'
})
export class RegistrationCodeService {
    constructor(
        private http: HttpClient
    ) { }

    generateRoleRegistrationCode(): Promise<string> {
        return new Promise(resolve => {
            this.http.get<RegistrationCodeWrapper>('/api/ClimbRole/GenerateRegistrationCode').subscribe(result => {
                resolve(result.regCode);
            });
        });
    }

    isRoleRegistrationCodeUnique(climbRoleKey: number, regCode: string): Promise<boolean> {
        return new Promise(resolve => {
            this.http.post('/api/ClimbRole/ValidateRegistrationCode', { climbRoleKey: climbRoleKey, registrationCode: regCode}).subscribe(result => {
                resolve(Boolean(result));
            });
        });
    }
}