<div class="p-2">
    <h1>Feature Flags</h1>
</div>
<p *ngIf="!flags"><em>Loading...</em></p>
<table class='table' *ngIf="flags">
    <thead>
        <tr>
            <!-- TODO: Re-enable delete column when flag descriptions are added to db/api -->
            <!--<th></th>-->
            <th>Key</th>
            <th>Description</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let flag of flags">
            <!-- TODO: Re-enable delete button when flag descriptions are added to db/api -->
            <!--<td>
                <button type="button" class="btn btn-link"
                        placement="top"
                        ngbTooltip="Delete"
                        tooltipClass="tooltipClass"
                        [openDelay]="500"
                        container="body"
                        (click)="clickedDeleteFlag(flag);">
                    <fa-icon icon="times" aria-hidden="true"></fa-icon>
                </button>
            </td>-->
            <td>{{flag.key}}</td>
            <td>{{flag.description}}</td>
        </tr>
    </tbody>
</table>



