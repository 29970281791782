export enum RouteEnum {
    Home = 'home',
    Workgroup = 'workgroup',
    User = 'user',
    Job = 'job',
    WorkgroupDetails = 'workgroup-details',
    UserDetails = 'user-details',
    FeatureFlags = 'feature-flags',
    Messaging = 'messaging',
    UsageReports = 'usage-reports'
}
