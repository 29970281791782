import { ClimbRole } from '../climb-roles-models';
import { UpdateClimbRoleModalComponent } from './update-climb-role-modal.component';

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class UpdateClimbRoleModalService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }


	openUpdateClimbRoleModal(climbRole: ClimbRole, workgroupKey: number): Promise<ClimbRole> {

        // Open modal
        const modalRef = this.modalService.open(
            UpdateClimbRoleModalComponent, this.modalOptions
        );
		let component = modalRef.componentInstance as UpdateClimbRoleModalComponent;
		component.climbRole = climbRole;
		component.workgroupKey = workgroupKey;

        return modalRef.result.then(
            (result) => {
				return climbRole;
            },
            (reason) => {
                return reason;
            });
    }
}
