import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UpdateFeatureFlagsModalService } from './modals/update-feature-flags-modal.service';
import { WorkgroupFeatureFlag } from './workgroup-feature-flags-models';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { ConfirmService } from '../common/confirm/confirm.service'

@Component({
    selector: 'workgroup-feature-flags-table',
    templateUrl: './workgroup-feature-flags-table.component.html',
    styleUrls: ['./workgroup-feature-flags-table.component.css']
})
export class WorkgroupFeatureFlagsTableComponent implements OnInit {
    @Input() workgroupKey: number;

    featureFlags: any;
    curWorkgroupKey: number;
	invalidFlagAlert: boolean = false;
	tableExpanded: boolean = false;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private updateFeatureFlagsModalService: UpdateFeatureFlagsModalService,
        private cd: ChangeDetectorRef,
        private confirmService: ConfirmService
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.curWorkgroupKey = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }

    initialize() {
        this.invalidFlagAlert = false;
        this.http.get(`/api/WorkgroupFeatureFlags/WorkgroupFeatureFlagsByWorkgroupKey/${this.curWorkgroupKey.toString()}`).subscribe(result => {
            this.featureFlags = result;
        });
    }

    clickedDeleteFeatureFlag(featureFlag: any) {
        this.confirmService.confirmDelete("Delete Feature Flag", `Are you sure you want to delete the ${featureFlag.key} feature flag?`).then(
            // Delete confirmed
            () => {
                this.http.delete(`/api/WorkgroupFeatureFlags/DeleteWorkgroupFeatureFlag/${featureFlag.workgroupFeatureFlagKey}`).subscribe(result => {
                    console.log("deleted workgroupFeatureFlagKey: ", result);
                    this.initialize();
                });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    clickedAddFeatureFlag() {
        var flagObject: WorkgroupFeatureFlag = {
            WorkgroupFeatureFlagKey: -1,
            WorkgroupKey: this.curWorkgroupKey,
            Key: null,
            Value: null,
            WorkgroupFeatureFlagTypeKey: null,
            IsActive: null
        }
        this.updateFeatureFlagsModalService.openUpdateFeatureFlagsModal(
            flagObject
        ).then((flag) => {
            if (!flag) {
                return;
            }
            else if (this.isValidFeatureFlag(flag)) {
                this.http.post<any>('/api/WorkgroupFeatureFlags/AddWorkgroupFeatureFlag', flag)
                    .subscribe({
                        next: response => {
                            console.log("added workgroupFeatureFlagKey: ", response);
                            this.initialize();
                        },
                        error: error => {
                            console.error('Save error: ', error);
                        }
                    });
            }
            else {
                this.invalidFlagAlert = true;
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    clickedEditFeatureFlag(flag: any) {
        var flagObject: WorkgroupFeatureFlag = {
            WorkgroupFeatureFlagKey: flag.workgroupFeatureFlagKey,
            WorkgroupKey: flag.workgroupKey,
            Key: flag.key,
            Value: flag.value,
            WorkgroupFeatureFlagTypeKey: flag.workgroupFeatureFlagTypeKey,
            IsActive: flag.isActive
        }
        this.updateFeatureFlagsModalService.openUpdateFeatureFlagsModal(
            flagObject
        ).then((flag) => {
            if (!flag) {
                return;
            }
            else if (this.isValidFeatureFlag(flag)) {
                this.http.put<any>('/api/WorkgroupFeatureFlags/UpdateWorkgroupFeatureFlag', flag)
                    .subscribe({
                        next: response => {
                            console.log("updated workgroupFeatureFlagKey: ", response);
                            this.initialize();
                        },
                        error: error => {
                            console.error('Save error: ', error);
                        }
                    });
            }
            else {
                this.invalidFlagAlert = true;
            }
            }).catch((error) => {
                console.error(error);
            });
    }

    isValidFeatureFlag(flag: WorkgroupFeatureFlag): boolean {
        if (flag.Key == null || flag.Key == "" || flag.Value == null || flag.Value == "" || flag.WorkgroupFeatureFlagTypeKey == null || flag.IsActive == null) {
            return false;
        }
        else {
            return true;
        }
    }

	clickedExpandTable(): any {
		this.tableExpanded = this.tableExpanded === false ? true : false;
	}
}

