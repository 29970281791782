<div class="p-2">
    <h1>Messaging</h1>
</div>
<form [formGroup]="form" (ngSubmit)="submit()">
    <div class="form-group row subject-group">
        <label for="subject" class="col-sm-2 col-form-label text-end">Subject</label>
        <div class="col-sm-8">
            <input type="text"
                   id="subject"
                   class="form-control"
                   formControlName="subject"
                   maxlength="150" 
                   placeholder="Max 150 characters"/>
        </div>
        <span class="col-sm-3 offset-2 help-block" *ngIf="!form.get('subject').valid && form.get('subject').touched">Please enter a subject.</span>
    </div>

    <div class="form-group row">
        <label for="message" class="col-sm-2 col-form-label text-end">Message</label>
        <div class="col-sm-8">
            <textarea class="form-control"
                      rows="3"
                      id="message"
                      formControlName="message"
                      placeholder="Max 2000 characters">
            </textarea>
        </div>
        <span *ngIf="!form.get('message').valid && form.get('message').touched" class="col-sm-3 offset-2 help-block">Please enter a message.</span>
    </div>

    <div class="row">
        <p class="col-sm-2 text-end">Workgroups</p>
        <div class="col-sm-10">
            <div class="form-check col-sm-10" *ngFor="let order of workgroupsFromArray.controls; let i = index" formArrayName="workgroups">
                <input class="form-check-input" type="checkbox" [formControlName]="i">
                <label class="form-check-label">{{workgroupData[i].name}}</label>
            </div>
            <span *ngIf="!form.get('workgroups').valid && workgroupsLoaded" class="col-sm-3 help-block workgroup-help">Please select at least one workgroup.</span>
            <span *ngIf="!workgroupsLoaded">Loading...</span>
            <div class="select-controls">
                <button type="button" class="btn btn-secondary" (click)="selectAll()">Select all</button>
                <button type="button" class="btn btn-outline-secondary" (click)="unselectAll()">Unselect all</button>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column col-sm-8 offset-2">
        <hr />
        <button class="btn btn-primary" type="submit" [disabled]="!form.valid">Submit message</button>
    </div>
</form>
