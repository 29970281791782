<div class="d-flex justify-content-between">
  <div class="p-2">
    <h1>
      Jobs
    </h1>
  </div>
  <div class="btn-group ml-auto p-2">
    <button type="button" class="btn btn-light"
            [disabled]="clicked"
            (click)="refreshRecentJobs()">
      Refresh
    </button>
    <button type="button" class="btn btn-primary"
            (click)="openNewModal(newModal)">
      New Job
    </button>
  </div>
</div>

<p *ngIf="!recentJobs"><em>Loading...</em></p>
<table class="table table-hover" *ngIf="recentJobs">
  <thead>
    <tr>
      <th>Status</th>
      <th>Name</th>
      <th>Group</th>
      <th>Start</th>
      <th>Command</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let job of recentJobs">
      <td>
        <button class="btn status"
                [ngClass]="{
                   'btn-success': job.status === 'Succeeded',
                   'btn-danger': job.status === 'Failed',
                   'btn-warning': (job.status !== 'Succeeded') && (job.status !== 'Failed')
                }"
                title="{{job.status}}"
                (click)="openDetailsModal(detailsModal, job)">
          {{ job.status }}
        </button>
      </td>
      <td>{{ job.name }}</td>
      <td>{{ job.targetGroup }}</td>
      <td>{{ job.startTime | date:'short' }}</td>
      <td>
        <button class="btn status" (click)="openCommandModal(commandModal, job)">Show</button>
      </td>
    </tr>
  </tbody>
</table>

<ng-template #newModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">New Job</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <form>
          <div class="form-group">
              <label for="job-name">Name</label>
              <input type="text" class="form-control"
                     id="job-name" name="job-name"
                     [(ngModel)]="job.name"
                     placeholder="e.g. 201908091130000_BLI9787_DEV"
                     required>
          </div>
          <div class="form-group">
              <label for="job-target-group">Target Group</label>
              <input type="text" class="form-control"
                     id="job-target-group" name="job-target-group"
                     [(ngModel)]="job.targetGroup"
                     placeholder={{targetGroup}}
                     readonly>

          </div>
          <div class="form-group">
              <label for="job-command">Command</label>
              <textarea class="form-control command" required
                        id="job-command" name="job-command"
                        [(ngModel)]="job.command"></textarea>
          </div>
      </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="modal.close('submit')"
            [disabled]="!((job.name && job.name.trim().length > 0) && (job.command && job.command.trim().length > 0))">
      Submit
    </button>
    <button type="button" class="btn btn-light" (click)="modal.dismiss()">Close</button>
  </div>
</ng-template>

<ng-template #detailsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{job.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table">
      <thead>
        <tr>
          <th>Status</th>
          <th>Elastic Pool</th>
          <th>Server</th>
          <th>Database</th>
          <th>Start</th>
          <th>End</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let detail of details">
          <tr>
            <td>
              <span class="badge status"
                    [ngClass]="{
                     'badge-success': detail.status === 'Succeeded',
                     'badge-danger': detail.status === 'Failed',
                     'badge-warning': (detail.status !== 'Succeeded') && (detail.status !== 'Failed')
                    }"
                    title="{{detail.status}}">{{ detail.status }}</span>
            </td>
            <td>{{ detail.elasticPool }}</td>
            <td>{{ detail.server }}</td>
            <td>{{ detail.database }}</td>
            <td>{{ detail.startTime | date:'short' }}</td>
            <td>{{ detail.endTime | date:'short' }}</td>
          </tr>
          <tr class="detail-message">
            <td colspan="6">{{ detail.lastMessage }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary"
            (click)="refreshDetails()">
      Refresh
    </button>
    <button type="button" class="btn btn-light" (click)="modal.close()">Close</button>
  </div>
</ng-template>

<ng-template #commandModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{job.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body command">
    {{ job.command }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.close()">Close</button>
  </div>
</ng-template>

<ng-template #resultModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{job.name}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        {{status}}
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.close()">Close</button>
    </div>
</ng-template>