<div>
    <div>
        <canvas id="studiesChart">{{ chart }}</canvas>
    </div>
    <table class="table">
        <tbody>
            <tr>
                <th>Study Name</th>
                <th>Created By</th>
                <th>Date Created</th>
                <th>Job Status</th>
                <th>Is End State</th>
                <th>Animals</th>
                <th>Goal</th>
            </tr>
            <tr *ngFor="let study of studyData">
                <td>{{ study.jobID }}</td>
                <td>{{ study.createdBy }}</td>
                <td>{{ study.dateCreated }}</td>
                <td>{{ study.jobStatus }}</td>
                <td>{{ study.isEndState }}</td>
                <td>{{ study.animals }}</td>
                <td>{{ study.goal }}</td>
            </tr>
        </tbody>
    </table>
</div>
<!--TODO: classes on divs-->