import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetModalService } from './password-reset-modal.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { BrowserModule } from '@angular/platform-browser';

@Component({
    selector: 'app-password-reset-modal',
    templateUrl: './password-reset-modal.component.html',
    styleUrls: ["./password-reset-modal.component.css"],
    standalone: true,
    imports: [ClipboardModule, BrowserModule],
})
export class PasswordResetModalComponent implements OnInit {
    modalOptions: NgbModalOptions;
    link: string;
    clicked = false;

    constructor(
        public activeModal: NgbActiveModal,
        private http: HttpClient,
        private passwordResetService: PasswordResetModalService,
    ) { }

    ngOnInit() {
        this.link = this.passwordResetService.getLink();
    }

    copyClicked() {
        this.clicked = true;
    }
}