import { RoutingService } from './routing.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        RoutingService
    ]
})
export class RoutingSharedModule { }
