<div class="modal-header">
    <h4 class="modal-title">{{updateType}} Workgroup Feature Flag</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="workgroupKey">Workgroup Key</label>
            <p id="workgroupKey" name="workgroupKey">{{flag.WorkgroupKey}}</p>
        </div>

        <div class="form-group">
            <label for="key">Key</label>
            <input [(ngModel)]="flag.Key" required id="key" name="key" class="form-control" />
        </div>

        <div class="form-group">
            <label for="value">Value</label>
            <input [(ngModel)]="flag.Value" required id="value" name="value" class="form-control" />
        </div>

        <div class="form-group">
            <label for="type">Type</label>
            <select [(ngModel)]="flag.WorkgroupFeatureFlagTypeKey" required id="type" name="type" class="form-control">
                <option *ngFor="let flagType of flagTypes" [ngValue]="flagType.workgroupFeatureFlagTypeKey">{{flagType.type}}</option>
            </select>
        </div>

        <div class="form-group">
            <label for="isActive">IsActive</label>
            <select [(ngModel)]="flag.IsActive" required id="isActive" name="isActive" class="form-control">
                <option *ngFor="let option of [true,false]" [ngValue]="option">{{option}}</option>
            </select>
        </div>

    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="activeModal.close('save')">
        Save
    </button>

    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
</div>