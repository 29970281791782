import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MessagingComponent } from './messaging.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageModalComponent } from './modals/message-modal/message-modal.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        MessageModalComponent,
    ],
    declarations: [
        MessagingComponent,
    ],
    exports: [
        MessagingComponent
    ],
    providers: [],
})
export class MessagingModule { }