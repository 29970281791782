import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';

import { Workgroup } from '../workgroup/workgroup';
import { Message } from './message';
import { MessageModalService } from './modals/message-modal/message-modal.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnInit {
    workgroupData: Workgroup[];
    form: FormGroup;
    selectedWorkgroups = [];
    workgroupsLoaded = false;

    constructor(private fb: FormBuilder,
        private http: HttpClient,
        private messageModalService: MessageModalService) {

        this.form = this.fb.group({
            subject: new FormControl(null, Validators.required),
            message: new FormControl(null, Validators.required),
            workgroups: new FormArray([], this.workgroupSelectionValidator)
        })
    }

    ngOnInit(): void {
        this.getWorkgroups();
    }

    workgroupSelectionValidator(array: FormArray): { [s: string]: boolean } {
        let checked = 0;

        array.controls.forEach(control => {
            if (control.value) {
                checked++;
            }
        })

        if (checked < 1) {
            return { 'noCheckboxesSelected': true };
        }
        return null;
    }

    getWorkgroups() {
        this.http.get('/api/Workgroup/Workgroups').subscribe(result => {
            this.workgroupData = result as Workgroup[];
            this.addCheckboxesToForm();
            this.workgroupsLoaded = true;
        });
    }

    get workgroupsFromArray() {
        return this.form.controls.workgroups as FormArray;
    }

    private addCheckboxesToForm() {
        this.workgroupData.forEach(() =>
            this.workgroupsFromArray.push(new FormControl(true)));
    }

    selectAll() {
        let trueArray = [];
        for (let i = 0; i < this.workgroupData.length; i++) {
            trueArray.push(true);
        }
        this.form.controls.workgroups.setValue(trueArray);
    }

    unselectAll() {
        let falseArray = [];
        for (let i = 0; i < this.workgroupData.length; i++) {
            falseArray.push(false);
        }
        this.form.controls.workgroups.setValue(falseArray);
    }

    submit() {
        this.selectedWorkgroups = this.form.value.workgroups.map((checked, i) => checked ? this.workgroupData[i].key : null)
            .filter(v => v !== null);

        const message: Message = {
            Subject: this.form.value.subject,
            MessageText: this.form.value.message,
            Workgroups: this.selectedWorkgroups,
        };

        this.messageModalService.setMessage(message);
        this.messageModalService.openMessageModal();
    }
}
