<div class="chart-container">
    <form (ngSubmit)="onSubmit()" #f="ngForm" class="d-flex align-items-center" id=form>
        <div class="form-group">
            <mat-form-field>
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date" />
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            </mat-form-field>
            <mat-error *ngIf="this.invalidDate">Please enter a start and end date</mat-error>
        </div>
        <div class="form-group select-group">
            <label for="tableSelect">Select a table</label>
            <select id="tableSelect"
                    class="form-control form-select"
                    [ngModel]="'Logins'"
                    name="table">
                <option value="Animal">Animals</option>
                <option value="Birth">Births</option>
                <option value="AnimalClinicalObservation">Clinical observations</option>
                <option value="Cohort">Cohorts</option>
                <option value="Genotype">Genotypes</option>
                <option value="MaterialPool">Locations</option>
                <option value="Mating">Matings</option>
                <option value="Protocol">Protocols</option>
                <option value="Sample">Samples</option>
                <option value="Study">Studies</option>
                <option value="TaskJob">Task jobs</option>
                <option value="Logins">User logins</option>
                <option value="Workspace">Workspaces</option>
            </select>
        </div>
        <div class="form-group logCheckbox">
            <input type="checkbox" name="logarithmic" id="logarithmic" class="form-check-input" />
            <label for="logarithmic" class="form-check-label">Logarithmic scale</label>
        </div>
        <button class="btn btn-primary" type="submit">Chart</button>
    </form>
    <div *ngIf="submitted && isStudies" class="alert alert-primary" role="alert">Note: studies with no animals have been omitted from the chart.</div>
    <div *ngIf="submitted && !isStudies">
        <canvas id="myChart">{{ chart }}</canvas>
    </div>
    <div *ngIf="submitted && isStudies">
        <app-chart-studies></app-chart-studies>
    </div>
</div>
