import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { Analytics_DateCount } from '../analytics_datecount/analytics_datecount';
import { Workgroup } from '../workgroup/workgroup';
import { UserLogins } from './user-logins';

interface wgLoginData {
    name: string;
    key: number;
    logins: number;
    userData: UserLogins[];
    detailsHidden: boolean;
    detailsLoaded: boolean;
}

interface downloadableWgLoginData {
    name: string;
    key: number;
    logins: number;
}

@Component({
    selector: 'app-usage-reports',
    templateUrl: './usage-reports.component.html',
    styleUrls: ['./usage-reports.component.scss']
})
export class UsageReportsComponent implements OnInit {
    @ViewChild('f') chartForm: NgForm;
    public startDate = null;
    public endDate = null;
    public invalidDate = false;
    public loaded = false;
    public submitted = false;

    public workgroups: Workgroup[];
    public workgroupsData: wgLoginData[];

    public wgHeaders: string[] = ["Workgroup name", "Keys", "Logins"];
    public userHeaders: string[] = ["Username", "Logins"];

    range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
    });

    constructor(private http: HttpClient,
                private ref: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.http.get('/api/Workgroup/Workgroups').subscribe(result => {
            this.workgroups = result as Workgroup[];
        });
    }

    getLoginData(dateCount: Analytics_DateCount, workgroup: Workgroup) {
        let countData;
        let totalLogins = 0;

        this.http.post('api/Analytics/Logins', dateCount).subscribe(logins => {

            countData = logins as Analytics_DateCount[];

            for (let i = 0; i < countData.length; i++) {
                totalLogins += countData[i].count;
            };

            const wgData: wgLoginData = {
                name: workgroup.name,
                key: workgroup.key,
                logins: totalLogins,
                userData: [],
                detailsHidden: true,
                detailsLoaded: false
            }

            this.workgroupsData.push(wgData);

            if (this.workgroupsData.length === this.workgroups.length) {
                this.workgroupsData.sort((d1, d2) => d1.name.localeCompare(d2.name));
                this.loaded = true;
                this.ref.detectChanges();
            }
        });
    }

    onSubmit() {
        this.loaded = false;
        this.workgroupsData = [];

        this.startDate = this.range.value.start;
        this.endDate = this.range.value.end;

        if (!(this.startDate === null || this.endDate === null)) {
            this.invalidDate = false;
            this.submitted = true;

            this.workgroups.forEach((workgroup) => {

                const dateCount: Analytics_DateCount = {
                    StartDate: this.startDate,
                    EndDate: this.endDate,
                    WorkgroupId: workgroup.key,
                    Table: null,
                    Date: '',
                    Count: 0,
                };

                this.getLoginData(dateCount, workgroup);
            })
        } else {
            this.invalidDate = true;
            this.submitted = false;
        }
    }

    downloadWgLoginData(data: wgLoginData[], columnHeaders: string[]) {
        const downloadable: downloadableWgLoginData[] = [];

        data.forEach(row => {
            const rowDownloadable: downloadableWgLoginData = {
                name: row.name,
                key: row.key,
                logins: row.logins
            }
            downloadable.push(rowDownloadable);
        })

        this.exportToCsv(downloadable, columnHeaders);
    }

    exportToCsv(data: (downloadableWgLoginData[] | UserLogins[]), columnHeaders: string[]) {
        if (!data || !data.length) {
            return;
        }

        const separator = ",";
        const keys: string[] = Object.keys(data[0]);

        const csvContent =
            "sep=,\n" +
            columnHeaders.join(separator) +
            '\n' +
            data.map(row => {
                return keys.map(k => {
                    let cell = row[k] === null || row[k] === undefined ? '' : row[k];

                    cell = cell instanceof Date
                        ? cell.toLocaleString()
                        : cell.toString().replace(/"/g, '""');

                    if (cell.search(/("|,|\n)/g) >= 0) {
                        cell = `"${cell}"`;
                    }
                    return cell;
                }).join(separator);
            }).join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', "usage-report");
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    getUsernameBreakdown(startDate: string, endDate: string, workgroupId: number) {
        let datecount: Analytics_DateCount = {
            StartDate: startDate,
            EndDate: endDate,
            WorkgroupId: workgroupId,
            Table: null,
            Date: '',
            Count: 0
        }

        this.http.post('api/Analytics/LoginsPerUser', datecount).subscribe(logins => {
            // logins has property values but not length value, while object is vice versa
            let object = logins as UserLogins[];

            this.workgroupsData.forEach(workgroup => {
                if (workgroup.key === workgroupId) {
                    let array: UserLogins[] = [];

                    for (let i = 0; i < object.length; i++) {
                        let user: UserLogins = {
                            Username: logins[i].username,
                            Logins: logins[i].logins
                        }
                        array.push(user);
                    }

                    workgroup.userData = array;

                    if (object.length > 0) {
                        workgroup.detailsLoaded = true;
                    }
                }
            })
        })
    }
}
