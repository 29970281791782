<div class="modal-header">
    <h4 class="modal-title">Add User to Workgroup</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="workgroup">Workgroup</label>
            <select [(ngModel)]="selectedWorkgroup" (ngModelChange)="workgroupChanged($event)"
                    required
                    id="workgroup`"
                    name="workgroup"
                    class="form-control">
                <option *ngFor="let workgroup of workgroups" [ngValue]="workgroup">{{workgroup.name}}</option>
            </select>
        </div>

        <div class="form-group">
            <label for="key">Role</label>
            <select [(ngModel)]="selectedRole"
                    required
                    id="role`"
                    name="role"
                    class="form-control">
                <option *ngFor="let role of roles" [ngValue]="role">{{role.roleName}}</option>
            </select>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="activeModal.close('save')">
        Save
    </button>

    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
</div>