import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UpdateClimbRoleModalService } from './modals/update-climb-role-modal.service'
import { ClimbRole, ClimbRoleFacet } from './climb-roles-models';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { ConfirmService } from '../common/confirm/confirm.service';

@Component({
    selector: 'climb-roles-table',
	templateUrl: './climb-roles-table.component.html',
	styleUrls: ['./Climb-roles-table.component.css']
})
export class ClimbRolesTableComponent implements OnInit {
    @Input() workgroupKey: number;

    climbRoles: any;
    curWorkgroupKey: number;
	invalidClimbRoleAlert: boolean = false;
	tableExpanded: boolean = false;
	invalidDeleteAlert: boolean = false;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
        private updateClimbRoleModalService: UpdateClimbRoleModalService,
        private confirmService: ConfirmService
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.curWorkgroupKey = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }

    initialize() {
        this.invalidClimbRoleAlert = false;
        this.http.get(`/api/ClimbRole/ClimbRolesByWorkgroupKey/${this.curWorkgroupKey.toString()}`).subscribe(result => {
            this.climbRoles = result;
        });
    }

	clickedDeleteClimbRole(climbRole: any) {
        this.confirmService.confirmDelete("Delete Role", `Are you sure you want to delete the ${climbRole.roleName} role?`).then(
            // Delete confirmed
            () => {
                this.http.delete(`/api/ClimbRole/DeleteClimbRole/${climbRole.climbRoleKey}`)
                    .subscribe({
                        next: response => {
                            console.log("deleted climbRoleKey: ", response);
                            this.initialize();
                        },
                        error: error => {
                            console.error(error);
                            this.invalidDeleteAlert = true;
                        }
                    });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    clickedAddClimbRole() {
		var climbRoleObject: ClimbRole = {
			climbRoleKey: -1,
			workgroupKey: this.curWorkgroupKey,
			roleName: null,
			registrationCode: null,
			climbRoleFacets: null
        }
        this.updateClimbRoleModalService.openUpdateClimbRoleModal(
            climbRoleObject,
			this.curWorkgroupKey
        ).then((climbRole) => {
            if (!climbRole) {
                return;
            } else if (this.isValidClimbRole(climbRole)) {
                this.http.post<any>('/api/ClimbRole/AddClimbRole', climbRole)
                    .subscribe({
                        next: response => {
							console.log("added climbRoleKey: ", response);
							this.updateClimbRoleFacets(climbRole.climbRoleFacets, response);
                            this.initialize();
                        },
                        error: error => {
                            console.error('Save error: ', error);
                        }
                    });
            }
            else {
                this.invalidClimbRoleAlert = true;
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    clickedEditClimbRole(climbRole: any) {
        var climbRoleObject: ClimbRole = {
            climbRoleKey: climbRole.climbRoleKey,
            workgroupKey: climbRole.workgroupKey,
			roleName: climbRole.roleName,
			registrationCode: climbRole.registrationCode,
			climbRoleFacets: null
        }
        this.updateClimbRoleModalService.openUpdateClimbRoleModal(
            climbRoleObject,
			this.curWorkgroupKey
        ).then((climbRole) => {
            if (!climbRole) {
                return;
            }
            else if (this.isValidClimbRole(climbRole)) {
                this.http.put<any>('/api/ClimbRole/UpdateClimbRole', climbRole)
                    .subscribe({
                        next: response => {
							console.log("updated climbRoleKey: ", response);
							this.updateClimbRoleFacets(climbRole.climbRoleFacets, response);
                            this.initialize();
                        },
                        error: error => {
                            console.error('Save error: ', error);
                        }
                    });
            }
            else {
                this.invalidClimbRoleAlert = true;
            }
            }).catch((error) => {
                console.error(error);
            });
    }

	isValidClimbRole(climbRole: ClimbRole): boolean {
		if (climbRole.roleName == null || climbRole.roleName == "" || climbRole.registrationCode == null || climbRole.registrationCode == "") {
            return false;
        }
        else {
            return true;
        }
	}

	updateClimbRoleFacets(climbRoleFacets: any[], response: any) {
		if (!climbRoleFacets || climbRoleFacets.length === 0) {
			return 0;
		}
		climbRoleFacets.forEach((climbRoleFacet) => {
			if (climbRoleFacet.climbRoleKey === -1) {
				climbRoleFacet.climbRoleKey = response;
			}
			if (climbRoleFacet.delete) {
				this.http.delete(`/api/ClimbRoleFacet/DeleteClimbRoleFacet/${climbRoleFacet.climbRoleFacetKey}`).subscribe(result => {
					console.log("deleted climbRoleFacetKey: ", result);
				});
			}
			else if (climbRoleFacet.climbRoleFacetKey < 0) {
				this.http.post<any>('/api/ClimbRoleFacet/AddClimbRoleFacet', climbRoleFacet)
					.subscribe({
						next: response => {
							console.log("created climbRoleFacetKey: ", response);
						},
						error: error => {
							console.error('Save error: ', error);
						}
					});
			} else {
				this.http.put<any>('/api/ClimbRoleFacet/UpdateClimbRoleFacet', climbRoleFacet)
					.subscribe({
						next: response => {
							console.log("updated climbRoleFacetKey: ", response);
						},
						error: error => {
							console.error('Save error: ', error);
						}
					});
			}
		});
	}

	clickedExpandTable(): any {
		this.tableExpanded = this.tableExpanded === false ? true : false;
	}
}

