import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Facet } from '../facets-models';
import { HttpClient } from '@angular/common/http';

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'update-facet-modal',
    templateUrl: './update-facet-modal.component.html'
})
export class UpdateFacetModalComponent implements OnInit {
    @Input() facet: Facet;

	updateType: string;
	facetNames: any;

    constructor(
		public activeModal: NgbActiveModal,
		public http: HttpClient
    ) { }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
		this.updateType = this.facet.FacetKey < 0 ? "Add" : "Edit"

		this.http.get(`/api/Facet/FacetNames/`).subscribe(result => {
			this.facetNames = result;
		});
    }
}
