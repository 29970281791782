<div class="d-flex">
    <table class='table' *ngIf="climbRoles">
        <thead>
            <tr>
                <th>
                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Add"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedAddClimbRole();">
                        <fa-icon icon="plus-circle" aria-hidden="true"></fa-icon>
                    </button>
                </th>
                <th>Role Name</th>
                <th>Registration Code</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let climbRole of climbRoles">
                <td>
                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Edit"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedEditClimbRole(climbRole);">
                        <fa-icon icon="pencil-alt" aria-hidden="true"></fa-icon>
                    </button>

                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Delete"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedDeleteClimbRole(climbRole);">
                        <fa-icon icon="times" aria-hidden="true"></fa-icon>
                    </button>
                </td>
                <td>{{ climbRole.roleName }}</td>
                <td>{{ climbRole.registrationCode }}</td>
            </tr>
        </tbody>
    </table>
</div>
<div>
	<ngb-alert *ngIf="invalidClimbRoleAlert" type="warning" dismissible="true" (close)="invalidClimbRoleAlert=false">
		Save failed: please fill in all values.
	</ngb-alert>
	<ngb-alert *ngIf="invalidDeleteAlert" type="danger" dismissible="true" (close)="invalidDeleteAlert=false">
		Delete failed: The selected Role cannot be deleted because of depentent data.
	</ngb-alert>
</div>
