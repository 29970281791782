import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faHome, faTasks, faThList, faRedo, faUser, faTimes, faPlusCircle, faPencilAlt, faChevronDown, faChevronUp, faFlag, faEnvelope, faClipboardList } from '@fortawesome/free-solid-svg-icons';

import { AppComponent } from './app.component';
import { HomeModule } from './components/home/home.module';
import { JobModule } from './components/job/job.module';
import { NavMenuModule } from './components/navmenu/navmenu.module';
import { UserModule } from './components/user/user.module';
import { UserDetailsModule } from './components/user-details/user-details.module';
import { WorkgroupModule } from './components/workgroup/workgroup.module';
import { WorkgroupDetailsModule } from './components/workgroup-details/workgroup-details.module';
import { RoutingSharedModule } from './components/routing/routing-shared.module';
import { ClimbCoreRoutingModule } from './components/routing/climb-core-routing.module';
import { WorkgroupFeatureFlagsModule } from './components/workgroup-feature-flags/workgroup-feature-flags.module';
import { CcaCommonModule } from './components/common/cca-common.module';
import { UpdateFeatureFlagsModalComponent } from './components/workgroup-feature-flags/modals/update-feature-flags-modal.component';
import { UpdateFacetModalComponent } from './components/facets/modals/update-facet-modal.component';
import { UpdateClimbRoleModalComponent } from './components/climb-roles/modals/update-climb-role-modal.component';
import { ConfirmModalComponent } from './components/common/confirm/confirm-modal.component';
import { AddToWorkgroupModalComponent } from './components/user-details/modals/add-to-workgroup-modal/add-to-workgroup-modal.component';
import { FeatureFlagsModule } from './components/feature-flags/feature-flags.module';
import { ChartDatecountModule } from './components/chart-datecount/chart-datecount.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MessagingModule } from './components/messaging/messaging.module';
import { UsageReportsModule } from './components/usage-reports/usage-reports.module';


@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        NgbModule,
        FontAwesomeModule,
        HomeModule,
        NavMenuModule,
        JobModule,
        UserModule,
        UserDetailsModule,
        ClimbCoreRoutingModule,
        RoutingSharedModule,
        WorkgroupModule,
        WorkgroupDetailsModule,
        WorkgroupFeatureFlagsModule,
        FeatureFlagsModule,
        CcaCommonModule,
        ChartDatecountModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MessagingModule,
        UsageReportsModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(faHome, faTasks, faThList, faRedo, faUser, faTimes, faPlusCircle, faPencilAlt, faChevronDown, faChevronUp, faFlag, faEnvelope, faClipboardList);
  }
}
