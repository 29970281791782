import { Injectable } from '@angular/core';
import { RouteEnum } from './route.enum';
import { Router } from '@angular/router';


@Injectable()
export class RoutingService {

    constructor(
        private router: Router
    ) {}

    navigateToWorkgroupDetailsWithKey(workgroupKey: number) {
        return this.router.navigate([RouteEnum.WorkgroupDetails, workgroupKey]);
    }

    navigateToUserDetailsWithKey(userKey: number) {
        return this.router.navigate([RouteEnum.UserDetails, userKey]);
    }

    navigateToHome() {
        return this.router.navigate([RouteEnum.Home]);
    }

    navigateToJob() {
        return this.router.navigate([RouteEnum.Job]);
    }

    navigateToUser() {
        return this.router.navigate([RouteEnum.User]);
    }

    navigateToWorkgroups() {
        return this.router.navigate([RouteEnum.Workgroup]);
    }

    navigateToFeatureFlags() {
        return this.router.navigate([RouteEnum.FeatureFlags]);
    }

    navigateToMessaging() {
        return this.router.navigate([RouteEnum.Messaging]);
    }

    navigateToUsageReports() {
        return this.router.navigate([RouteEnum.UsageReports]);
    }
}
