import { WorkgroupFeatureFlag } from '../workgroup-feature-flags-models';
import { UpdateFeatureFlagsModalComponent } from './update-feature-flags-modal.component';

import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';


@Injectable()
export class UpdateFeatureFlagsModalService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }


    openUpdateFeatureFlagsModal(flag: WorkgroupFeatureFlag): Promise<WorkgroupFeatureFlag> {

        // Open modal
        const modalRef = this.modalService.open(
            UpdateFeatureFlagsModalComponent, this.modalOptions
        );
        let component = modalRef.componentInstance as UpdateFeatureFlagsModalComponent;
        component.flag = flag;

        return modalRef.result.then(
            (result) => {
                return flag;
            },
            (reason) => {
                return reason;
            });
    }
}
