import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, Input, ViewChild } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { Analytics_DateCount } from '../analytics_datecount/analytics_datecount';
import { FormGroup, FormControl, NgForm } from '@angular/forms';
import { ChartsService } from './charts.service';
Chart.register(...registerables);

@Component({
    selector: 'app-chart-datecount',
    templateUrl: './chart-datecount.component.html',
    styleUrls: ['./chart-datecount.component.scss'],
})
export class ChartDatecountComponent implements OnInit {
    @ViewChild('f') chartForm: NgForm;
    @Input() workgroupKey;
    public startDate = null;
    public endDate = null;
    private table = null;

    public submitted = false;
    public invalidDate = false;
    private alreadySubmitted = false;
    public isStudies = false;
    public isLogarithmic = false;

    public chart: any;
    private countData;
    private chartLabels: string[] = [];
    private chartData: number[] = [];

    public sum: number;

    range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null),
    });

    constructor(private elementRef: ElementRef,
        private http: HttpClient,
        private chartsService: ChartsService) {
    }

    ngOnInit() {
    }

    createChart() {

        if (this.alreadySubmitted) {
            this.chart.destroy();
        }

        let label: string;

        if (this.table === 'Logins') {
            label = 'Number of User Logins';
        } else {
            label = 'Number of ' + this.table + 's Added';
        }

        let htmlRef = this.elementRef.nativeElement.querySelector('#myChart');
        this.chart = new Chart(htmlRef, {
            type: 'bar',
            data: {
                labels: this.chartLabels,
                datasets: [{
                    label: label,
                    data: this.chartData,
                    borderColor: 'rgb(104, 170, 70)',
                    backgroundColor: 'rgb(104, 170, 70)',
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: label + ': ' + this.startDate.toLocaleDateString('en-US')
                            + ' - ' + this.endDate.toLocaleDateString('en-US'),
                        font: {
                            size: 20,
                        }
                    },
                    subtitle: {
                        display: true,
                        text: "Total: " + this.sum,
                        font: {
                            size: 16,
                        }
                    }
                }
            }
        });

        if (this.isLogarithmic) {
            this.chart.options = {
                scales: {
                    y: {
                        type: "logarithmic",
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: label + ': ' + this.startDate.toLocaleDateString('en-US')
                            + ' - ' + this.endDate.toLocaleDateString('en-US'),
                        font: {
                            size: 20,
                        }
                    },
                    subtitle: {
                        display: true,
                        text: "Total: " + this.sum,
                        font: {
                            size: 16,
                        }
                    }
                }
            }
            this.chart.update();
        }
    }

    getData() {

        if (this.alreadySubmitted) {
            this.chartLabels = [];
            this.chartData = [];
        }

        const dateCount: Analytics_DateCount = {
            StartDate: this.startDate,
            EndDate: this.endDate,
            WorkgroupId: Number(this.workgroupKey),
            Table: this.table,
            Date: '',
            Count: 0,
        };

        if (this.table === 'Logins') {
            this.http.post('api/Analytics/Logins', dateCount).subscribe(logins => {
                this.countData = logins as Analytics_DateCount[];

                for (let i = 0; i < this.countData.length; i++) {
                    this.chartLabels.push(this.countData[i].date);
                    this.chartData.push(this.countData[i].count);
                };

                this.getTotalSum();
                this.createChart();
                this.alreadySubmitted = true;

            });
        } else {
            this.http.post('api/Analytics/Tables', dateCount).subscribe(value => {
                this.countData = value as Analytics_DateCount[];

                for (let i = 0; i < this.countData.length; i++) {
                    this.chartLabels.push(this.countData[i].date);
                    this.chartData.push(this.countData[i].count);
                };

                this.getTotalSum();
                this.createChart();
                this.alreadySubmitted = true;
            });
        }
    }

    getTotalSum() {
        this.sum = 0;
        this.chartData.forEach((totalDay) => {
            this.sum += totalDay;
        })
    }

    onSubmit() {
        this.startDate = this.range.value.start;
        this.endDate = this.range.value.end;
        this.table = this.chartForm.value.table;

        var logCheckbox = this.elementRef.nativeElement.querySelector('#logarithmic');
        if (logCheckbox.checked) {
            this.isLogarithmic = true;
        } else {
            this.isLogarithmic = false;
        }
        
        if (!(this.startDate === null || this.endDate === null)) {
            if (this.table === 'Study') {
                this.isStudies = true;
                if (this.alreadySubmitted) {
                    this.chart.destroy();
                }
                this.chartsService.setData({ workgroupKey: this.workgroupKey, startDate: this.startDate, endDate: this.endDate, isLogarithmic: this.isLogarithmic });
                this.submitted = true;

            }
            else {
                this.isStudies = false;
                this.invalidDate = false;
                this.getData();
                this.submitted = true;
            }
        } else {
            this.invalidDate = true;
        }
    }
}
