import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmService } from '../common/confirm/confirm.service';
import { PasswordReset } from '../passwordreset/passwordreset';
import { User } from '../user/user';
import { Workgroup } from '../workgroup/workgroup';
import { AddToWorkgroupModalService } from './modals/add-to-workgroup-modal/add-to-workgroup-modal.service';
import { PasswordResetModalService } from './modals/password-reset-modal/password-reset-modal.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
    user: User;
    userKey: string = "";
    tableExpanded: boolean = false;
    passwordResetData: PasswordReset;
    passwordResetLink;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private addToWorkgroupService: AddToWorkgroupModalService,
        private confirmService: ConfirmService,
        private passwordResetService: PasswordResetModalService,
    ) { }

    ngOnInit() {
        this.userKey = this.route.snapshot.paramMap.get("id");
        this.fetchUserDetails();
    }

    fetchUserDetails() {
        this.http.get<User>(`/api/User/UserDetailsByKey/${this.userKey}`).subscribe(result => {
            this.user = result;
            console.log(this.user);
        });
    }

    passwordResetClick() {
        this.http.get<PasswordReset>(`/api/User/PasswordResetLink/${this.userKey}`).subscribe(result => {
            this.passwordResetData = result;

            if (!this.passwordResetData) {
                this.passwordResetLink = "No active password reset requests for this user.";
            } else {
                this.passwordResetLink = this.passwordResetData.resetURL + "?resetid=" + this.passwordResetData.requestGuid;
            }

            this.passwordResetService.setLink(this.passwordResetLink);
            this.passwordResetService.openPasswordResetModal();
        });
    }

    clickedRemoveUserFromWorkgroup(workgroup: Workgroup) {
        this.confirmService.confirmDelete("Remove from Workgroup", `Are you sure you want to remove ${this.user.userName} from the ${workgroup.name} workgroup?`).then(
            () => {
                // Delete confirmed
                this.http.delete(`/api/Workgroup/RemoveUserFromWorkgroup/${workgroup.key}/${this.userKey}`).subscribe(result => {
                    this.user.workgroups = this.user.workgroups.filter(w => w.key !== workgroup.key);
                });
            }, () => { /* Do nothing on cancel */ }
        );
    }

    clickedAddUserToWorkgroup() {
        this.addToWorkgroupService.openAddToWorkgroupModal().then((result) => {
            if (!result) {
                return;
            }

            const { workgroupKey, roleName } = result;
            const body = { userKey: this.userKey, roleName };
            // TODO: Add more robust vaildation
            if (!this.userKey || this.userKey.length === 0 || !roleName || roleName.length === 0) {
                return;
            }

            this.http.post(`/api/Workgroup/AddUserToWorkgroup/${workgroupKey}`, body).subscribe(result => {
                this.fetchUserDetails();
            });
            
        }).catch((error) => {
            console.error(error);
        });
    }

    clickedExpandTable(): void {
        this.tableExpanded = !this.tableExpanded;
    }
}
