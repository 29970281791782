import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FacetsTableComponent } from './facets-table.component';
import { UpdateFacetModalComponent } from './modals/update-facet-modal.component';
import { UpdateFacetModalService } from './modals/update-facet-modal.service';
import { CcaCommonModule } from '../common/cca-common.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        NgbModule,
        CcaCommonModule
    ],
    declarations: [
		FacetsTableComponent,
		UpdateFacetModalComponent
    ],
    exports: [
		FacetsTableComponent,
		UpdateFacetModalComponent
    ],
    providers: [
		UpdateFacetModalService
    ]
})
export class FacetsModule { }
