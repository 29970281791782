import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { WorkgroupFeatureFlag } from '../workgroup-feature-flags-models';

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

@Component({
    selector: 'update-feature-flags-modal',
    templateUrl: './update-feature-flags-modal.component.html'
})
export class UpdateFeatureFlagsModalComponent implements OnInit {
    @Input() flag: WorkgroupFeatureFlag;

    flagTypes: any; // list of WorkgroupFeatureFlagTypes
    updateType: string;

    constructor(
        public activeModal: NgbActiveModal,
        private http: HttpClient
    ) { }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
        this.http.get(`/api/WorkgroupFeatureFlags/WorkgroupFeatureFlagTypes`).subscribe(result => {
            this.flagTypes = result;
        });
        this.updateType = this.flag.WorkgroupFeatureFlagKey < 0 ? "Add" : "Edit"
    }
}
