import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Workgroup } from '../../../workgroup/workgroup';
import { Message } from '../../message';
import { MessageModalService } from './message-modal.service';


@Component({
    selector: 'app-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss'],
    standalone: true,
    imports: [BrowserModule],
})
export class MessageModalComponent implements OnInit {
    message: Message;
    workgroupNames: string[] = [];
    result;
    sent = false;
    constructor(public activeModal: NgbActiveModal,
        private messageModalService: MessageModalService,
        private http: HttpClient,) { }

    ngOnInit(): void {
        this.message = this.messageModalService.getMessage();
    }

    /*workgroupNamesFromMessage() {
        this.message.Workgroups.forEach(function (workgroupKey) {
            this.http.get(`/api/Workgroup/WorkgroupDetailsByKey/${workgroupKey}`).subscribe(result => {
                let workgroup = result as Workgroup;
                this.workgroupNames.push(workgroup.name);
            });
        }, this);
    }*/

    finalSubmit() {
        this.http.post('/api/Message/SendMessage', this.message).subscribe(result => {
            this.result = result;
            this.sent = true;
        });

    }
}
