<div class="d-flex justify-content-between">
    <div class="p-2">
        <h1>
            Workgroups
        </h1>
    </div>
    <div class="btn-group ml-auto p-2">
        <button (click)="getWorkgroups()" class="btn btn-light">Refresh</button>
        <button class="btn btn-primary"
                (click)="openNewModal(newModal)">
            Create Workgroup
        </button>
    </div>
</div>

<p *ngIf="!workgroups"><em>Loading...</em></p>

<table class='table' *ngIf="workgroups">
    <thead>
        <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Key</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let workgroup of workgroups" (click)="navigateToWorkgroupDetails(workgroup.key)">
            <td>{{ workgroup.name }}</td>
            <td>{{ workgroup.workgroupStatusType }}</td>
            <td>{{ workgroup.key }}</td>
        </tr>
    </tbody>
</table>

<ng-template #newModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Add Workgroup</h4>
        <button type="button" class="close btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <form #f="ngForm">
            <div class="form-group">
                <label for="customer">Customer</label>
                <select [(ngModel)]="selectedCustomer" required id="customer" name="customer" class="form-control" #customer="ngModel"
                        [ngClass]="{'invalid-field' : !customer.valid && customer.touched}">
                    <option *ngFor="let c of customers" [ngValue]="c.key">{{c.name}}</option>
                </select>
                <span class="help-block" *ngIf="!customer.valid && customer.touched">Customer is required.</span>
            </div>
            <div class="form-group">
                <label for="server">Server</label>
                <select [(ngModel)]="selectedServer" required id="server" name="server" class="form-control" #server="ngModel"
                        [ngClass]="{'invalid-field' : !server.valid && server.touched}">
                    <option *ngFor="let s of servers" [ngValue]="s.key">{{s.name}}</option>
                </select>
                <span class="help-block" *ngIf="!server.valid && server.touched">Server is required.</span>
            </div>
            <div class="form-group">
                <label for="template">Template</label>
                <select [(ngModel)]="selectedTemplate" required id="template" name="template" class="form-control" #template="ngModel"
                        [ngClass]="{'invalid-field' : !template.valid && template.touched}">
                    <option *ngFor="let w of workgroups" [ngValue]="w.key">{{w.name}}</option>
                </select>
                <span class="help-block" *ngIf="!template.valid && template.touched">Template is required.</span>
            </div>
            <div class="form-group">
                <label for="status">Status</label>
                <select [(ngModel)]="selectedStatus" required id="status" name="status" class="form-control" #status="ngModel"
                        [ngClass]="{'invalid-field' : !status.valid && status.touched}">
                    <option *ngFor="let t of statuses" [ngValue]="t">{{t}}</option>
                </select>
                <span class="help-block" *ngIf="!status.valid && status.touched">Status is required.</span>
            </div>
            <div class="form-group">
                <label for="workgroupname">Workgroup Name</label>
                <input [(ngModel)]="workgroupName" required id="workgroupname" name="workgroupname" class="form-control" #wgName="ngModel"
                       [ngClass]="{'invalid-field' : !wgName.valid && wgName.touched}" />
                <span class="help-block" *ngIf="!wgName.valid && wgName.touched">Workgroup name is required.</span>
            </div>
            <div class="form-group">
                <label for="regcode">Admin Registration Code</label>
                <div style="display: flex; align-items: center;">
                    <input [(ngModel)]="registrationCode" required id="regcode" name="regcode" class="form-control" disabled />
                    <button type="button" class="btn btn-light btn-reset" (click)="generateRegistrationCode()">Reset</button>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="!f.valid"
                (click)="modal.close('submit')">
            Create
        </button>

        <button type="button" class="btn btn-light" (click)="modal.dismiss()">Close</button>
    </div>
</ng-template>
