import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {
    private _data: BehaviorSubject<{ workgroupKey: string, startDate: string, endDate: string, isLogarithmic: boolean }> =
        new BehaviorSubject<{ workgroupKey: string, startDate: string, endDate: string, isLogarithmic: boolean }>(null);
    constructor() { }

    public setData(data: { workgroupKey: string, startDate: string, endDate: string, isLogarithmic: boolean }) {
        this._data.next(data);
    }

    public getData(): Observable<{ workgroupKey: string, startDate: string, endDate: string, isLogarithmic: boolean }> {
        return this._data.asObservable();
    }
}
