import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetModalComponent } from './password-reset-modal.component';


@Injectable({
    providedIn: 'root'
})
export class PasswordResetModalService {
    modalOptions: NgbModalOptions;

    private _link: string;

    constructor(private modalService: NgbModal) {
        this.init();
    }

    private init() {
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }

    public setLink(link: string) {
        this._link = link;
    }

    public getLink(): string {
        return this._link;
    }

    openPasswordResetModal(): Promise<any> {
        const modalRef = this.modalService.open(
            PasswordResetModalComponent, this.modalOptions
        );
        const component = modalRef.componentInstance as PasswordResetModalComponent;
        return;
    }
}