import { RouteEnum } from './route.enum';
import { HomeComponent } from './../home/home.component';
import { WorkgroupComponent } from './../workgroup/workgroup.component';
import { UserComponent } from './../user/user.component';
import { JobComponent } from './../job/job.component';
import { WorkgroupDetailsComponent } from './../workgroup-details/workgroup-details.component';
import { NgModule } from '@angular/core';
import { 
    Routes, 
    RouterModule, 
    PreloadAllModules,
    NoPreloading
} from '@angular/router';
import { RoutingSharedModule } from './routing-shared.module';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { FeatureFlagsComponent } from '../feature-flags/feature-flags.component';
import { MessagingComponent } from '../messaging/messaging.component';
import { UsageReportsComponent } from '../usage-reports/usage-reports.component';

const routes: Routes = [
    {
        path: RouteEnum.Home,
        component: HomeComponent
    },
    {
        path: RouteEnum.Workgroup,
        component: WorkgroupComponent
    },
    {
        path: RouteEnum.User,
        component: UserComponent
    },
    {
        path: RouteEnum.Job,
        component: JobComponent
    },
    {
        path: RouteEnum.WorkgroupDetails + '/:id', 
        component: WorkgroupDetailsComponent
    },
    {
        path: RouteEnum.UserDetails + '/:id',
        component: UserDetailsComponent
    },
    {
        path: RouteEnum.FeatureFlags,
        component: FeatureFlagsComponent
    },
    {
        path: RouteEnum.Messaging,
        component: MessagingComponent
    },
    {
        path: RouteEnum.UsageReports,
        component: UsageReportsComponent
    },
    // blank route
    {
        path: '',
        redirectTo: RouteEnum.Home,
        pathMatch: 'full'
    },
    // route not found
    { 
        path: '**', 
        redirectTo: '/' + RouteEnum.Home,
    },
];

@NgModule({
    imports: [
        RoutingSharedModule,
        RouterModule.forRoot(routes, {
    preloadingStrategy: NoPreloading,
    useHash: true,
    initialNavigation: 'enabledNonBlocking',
    relativeLinkResolution: 'legacy'
})
    ],
    exports: [RouterModule]
})
export class ClimbCoreRoutingModule {}
