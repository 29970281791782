<div>
    <div class="modal-header">
    <h4 class="modal-title">Confirm Message</h4>
    <button type="button" class="close btn" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <p class="text-display"><b>Subject:</b> {{message.Subject}}</p>
    <p class="text-display"><b>Message:</b> {{message.MessageText}}</p>
    <p><b>Total workgroups to receive message:</b> {{message.Workgroups.length}}</p>
</div>

<div class="modal-footer justify-content-start align-items-center">
    <button *ngIf="!sent" type="button" class="btn btn-primary" (click)="finalSubmit()">
        Send message
    </button>
    <div *ngIf="sent" class="alert alert-success align-content-center" role="alert">Message sent!</div>

    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
</div>