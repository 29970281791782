import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { WorkgroupDetailsComponent } from './workgroup-details.component';
import { RoutingSharedModule } from '../routing/routing-shared.module';
import { WorkgroupFeatureFlagsModule } from '../workgroup-feature-flags/workgroup-feature-flags.module';
import { FacetsModule } from '../facets/facets.module';
import { ClimbRolesModule } from '../climb-roles/climb-roles.module';
import { ChartDatecountModule } from '../chart-datecount/chart-datecount.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RoutingSharedModule,
		WorkgroupFeatureFlagsModule,
		FacetsModule,
        ClimbRolesModule,
        MatTabsModule,
        BrowserAnimationsModule,
        ChartDatecountModule,
        ReactiveFormsModule,
    ],
    declarations: [
        WorkgroupDetailsComponent,
    ],
    exports: [
        WorkgroupDetailsComponent
    ],
    providers: [
    ]
})
export class WorkgroupDetailsModule { }
