<div class="d-flex">
    <table class='table' *ngIf="featureFlags">
        <thead>
            <tr>
                <th>
                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Add"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedAddFeatureFlag();">
                        <fa-icon icon="plus-circle" aria-hidden="true"></fa-icon>
                    </button>
                </th>
                <th>Key</th>
                <th>Value</th>
                <th>Type</th>
                <th>IsActive</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let flag of featureFlags">
                <td>
                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Edit"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedEditFeatureFlag(flag);">
                        <fa-icon icon="pencil-alt" aria-hidden="true"></fa-icon>
                    </button>

                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Delete"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedDeleteFeatureFlag(flag);">
                        <fa-icon icon="times" aria-hidden="true"></fa-icon>
                    </button>
                </td>
                <td>{{ flag.key }}</td>
                <td>
                    <ng-container *ngIf="flag.workgroupFeatureFlagType !== 'hidden'">
                        {{ flag.value }}
                    </ng-container>
                    <ng-container *ngIf="flag.workgroupFeatureFlagType === 'hidden'">
                        <i>Hidden value. Click edit button to view</i>
                    </ng-container>
                </td>
                <td>{{ flag.workgroupFeatureFlagType }}</td>
                <td>{{ flag.isActive }}</td>
            </tr>
        </tbody>
    </table>
</div>
<div>
    <ngb-alert *ngIf="invalidFlagAlert" type="warning" dismissible="true" (close)="invalidFlagAlert=false">
        Save failed: please fill in all values.
    </ngb-alert>
</div>
