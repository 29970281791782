import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Workgroup } from './workgroup';
import { Customer } from '../customer/customer';
import { Server } from '../server/server';
import { RoutingService } from './../routing/routing.service';
import { NgForm } from '@angular/forms';
import { RegistrationCodeService } from '../common/registration-code.service';

@Component({
    selector: 'workgroup',
    templateUrl: './workgroup.component.html',
    styleUrls: ['./workgroup.component.css'],
})
export class WorkgroupComponent implements OnInit {
    public workgroups: Workgroup[];
    public customers: Customer[];
    public servers: Server[];
    public statuses: string[];
    selectedCustomer: any;
    selectedServer: any;
    selectedStatus: any;
    selectedTemplate: any;
    registrationCode: any;
    workgroupName: any;

    @ViewChild('f') signupForm: NgForm;

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        private routingService: RoutingService,
        private registrationCodeService: RegistrationCodeService
    ) {
    // Do nothing
    }

    ngOnInit() {
        this.http.get('/api/Workgroup/Workgroups').subscribe(result => {
            this.workgroups = result as Workgroup[];
        });
        this.http.get('/api/Customer/Customers').subscribe(result => {
            this.customers = result as Customer[];
        });
        this.http.get('/api/Server/Servers').subscribe(result => {
            this.servers = result as Server[];
        });
        this.statuses = ["Active", "Uninitialized"];
    }

    getWorkgroups() {
        this.http.get('/api/Workgroup/Workgroups').subscribe(result => {
            this.workgroups = result as Workgroup[];
        });
    }

    navigateToWorkgroupDetails(workgroupKey: number) {
        this.routingService.navigateToWorkgroupDetailsWithKey(workgroupKey);
    }

    async generateRegistrationCode() {
        this.registrationCode = await this.registrationCodeService.generateRoleRegistrationCode();
    }

    async openNewModal(modal): Promise<any> {
        await this.generateRegistrationCode();
        const ref = this.modalService.open(modal);
        return ref.result.then(
            (_: any) => {
                this.createWorkgroup();
            },
            () => {
                // Cancelled. Do nothing
            }
        );
    }

    createWorkgroup() {
        let body = {
            Name: this.workgroupName,
            RegistrationCode: this.registrationCode,
            TemplateKey: this.selectedTemplate,
            CustomerKey: this.selectedCustomer,
            ServerKey: this.selectedServer,
            WorkgroupStatusType: this.selectedStatus
        };

        this.http
            .post('/api/Workgroup/AddWorkgroup', body)
            .subscribe(data => {
                alert('Your new workgroup has been created');
                this.getWorkgroups();
                this.clearValues();
            }, error => {
                // error handling here
            });

    }

    clearValues() {
        this.registrationCode = '';
        this.selectedTemplate = '';
        this.selectedCustomer = '';
        this.selectedServer = '';
        this.selectedStatus = '';
        this.workgroupName = '';
    }
}

