import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { WorkgroupFeatureFlagsTableComponent } from './workgroup-feature-flags-table.component';
import { UpdateFeatureFlagsModalComponent } from './modals/update-feature-flags-modal.component';
import { UpdateFeatureFlagsModalService } from './modals/update-feature-flags-modal.service';
import { CcaCommonModule } from '../common/cca-common.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        NgbModule,
        CcaCommonModule
    ],
    declarations: [
        WorkgroupFeatureFlagsTableComponent,
        UpdateFeatureFlagsModalComponent
    ],
    exports: [
        WorkgroupFeatureFlagsTableComponent,
        UpdateFeatureFlagsModalComponent
    ],
    providers: [
        UpdateFeatureFlagsModalService
    ]
})
export class WorkgroupFeatureFlagsModule { }
