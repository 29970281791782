<div class="p-2">
    <h1>User Details</h1>
</div>
<p *ngIf="!user"><em>Loading...</em></p>

<table class="table" *ngIf="user">
    <tbody>
        <tr class="striped">
            <th class="">Username</th>
            <td>{{user.userName}}</td>
        </tr>
        <tr>
            <th>Email</th>
            <td>{{user.emailAddress}}</td>
        </tr>
        <tr class="striped">
            <th>Key</th>
            <td>{{user.key}}</td>
        </tr>
        <tr>
            <th>First Name</th>
            <td>{{user.firstName}}</td>
        </tr>
        <tr class="striped">
            <th>Last Name</th>
            <td>{{user.lastName}}</td>
        </tr>
        <tr>
            <th>Role:</th>
            <td>{{user.role}}</td>
        </tr>
    </tbody>
</table>

<div class="d-flex reset-button">
    <button type="button" (click)="passwordResetClick()" class="btn btn-primary">Get password reset URL</button>
</div>

<div class="table-header">
    <h4 class="header-element">
        Workgroups
    </h4>
</div>

<div class="d-flex">
    <table class='table workgroup-table' *ngIf="user && user.workgroups">
        <thead>
            <tr>
                <th>
                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Add"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedAddUserToWorkgroup();">
                        <fa-icon icon="plus-circle" aria-hidden="true"></fa-icon>
                    </button>
                </th>
                <th>Workgroup</th>
                <th>Key</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let workgroup of user.workgroups">
                <td>
                    <button type="button" class="btn btn-link"
                            placement="top"
                            ngbTooltip="Delete"
                            tooltipClass="tooltipClass"
                            [openDelay]="500"
                            container="body"
                            (click)="clickedRemoveUserFromWorkgroup(workgroup);">
                        <fa-icon icon="times" aria-hidden="true"></fa-icon>
                    </button>
                </td>
                <td>{{ workgroup.name }}</td>
                <td>{{ workgroup.key }}</td>
            </tr>
        </tbody>
    </table>
</div>