import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Message } from '../../message';
import { MessageModalComponent } from './message-modal.component';

@Injectable({
    providedIn: 'root'
})
export class MessageModalService {
    modalOptions: NgbModalOptions;
    private _message: Message;

    constructor(private modalService: NgbModal) {
        this.init();
    }

    private init() {
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }

    public setMessage(message: Message) {
        this._message = message;
    }

    public getMessage(): Message {
        return this._message;
    }

    openMessageModal(): Promise<any> {
        const modalRef = this.modalService.open(
            MessageModalComponent, this.modalOptions
        );
        const component = modalRef.componentInstance as MessageModalComponent;
        return;
    }
}