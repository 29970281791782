import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { UserDetailsComponent } from './user-details.component';
import { RoutingSharedModule } from '../routing/routing-shared.module';
import { FacetsModule } from '../facets/facets.module';
import { ClimbRolesModule } from '../climb-roles/climb-roles.module';;
import { AddToWorkgroupModalComponent } from './modals/add-to-workgroup-modal/add-to-workgroup-modal.component'
import { AddToWorkgroupModalService } from './modals/add-to-workgroup-modal/add-to-workgroup-modal.service';
import { PasswordResetModalService } from './modals/password-reset-modal/password-reset-modal.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RoutingSharedModule,
		FacetsModule,
        ClimbRolesModule,
        FontAwesomeModule,
        NgbModule
    ],
    declarations: [
        UserDetailsComponent,
        AddToWorkgroupModalComponent
    ],
    exports: [
        UserDetailsComponent
    ],
    providers: [
        AddToWorkgroupModalService,
        PasswordResetModalService,
    ]
})
export class UserDetailsModule { }
