import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UpdateFacetModalService } from './modals/update-facet-modal.service'
import { Facet } from './facets-models';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { ConfirmService } from '../common/confirm/confirm.service';

@Component({
    selector: 'facets-table',
	templateUrl: './facets-table.component.html',
	styleUrls: ['./facets-table.component.css']
})
export class FacetsTableComponent implements OnInit {
    @Input() workgroupKey: number;

    facets: any;
    curWorkgroupKey: number;
	invalidFacetAlert: boolean = false;
	tableExpanded: boolean = false;
	invalidDeleteAlert: boolean = false;

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
		private cd: ChangeDetectorRef,
        private updateFacetModalService: UpdateFacetModalService,
        private confirmService: ConfirmService
    ) {
        // Do nothing
    }

    ngOnInit() {
        this.curWorkgroupKey = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }

    initialize() {
        this.invalidFacetAlert = false;
        this.http.get(`/api/Facet/FacetsByWorkgroupKey/${this.curWorkgroupKey.toString()}`).subscribe(result => {
            this.facets = result;
        });
    }

    clickedDeleteFacet(facet: any) {
        this.confirmService.confirmDelete("Delete Facet", `Are you sure you want to delete the ${facet.facetName} facet?`).then(
            // Delete confirmed
            () => {
                this.http.delete(`/api/Facet/DeleteFacet/${facet.facetKey}`).subscribe({
                    next: response => {
                        console.log("deleted facetKey: ", response);
                        this.initialize();
                    },
                    error: error => {
                        console.error(error);
                        this.invalidDeleteAlert = true;
                    }
                });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    clickedAddFacet() {
		var facetObject: Facet = {
			FacetKey: -1,
			WorkgroupKey: this.curWorkgroupKey,
			FacetName: null,
            FacetDisplayName: null,
            Image: null
        }
        this.updateFacetModalService.openUpdateFacetModal(
            facetObject
        ).then((facet) => {
            if (!facet) {
                return;
            }
            else if (this.isValidFacet(facet)) {
                this.http.post<any>('/api/Facet/AddFacet', facet)
                    .subscribe({
                        next: response => {
                            console.log("added facetKey: ", response);
                            this.initialize();
                        },
                        error: error => {
                            console.error('Save error: ', error);
                        }
                    });
            }
            else {
                this.invalidFacetAlert = true;
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    clickedEditFacet(facet: any) {
        var facetObject: Facet = {
            FacetKey: facet.facetKey,
            WorkgroupKey: facet.workgroupKey,
			FacetName: facet.facetName,
			FacetDisplayName: facet.facetDisplayName,
			Image: facet.image
        }
        this.updateFacetModalService.openUpdateFacetModal(
            facetObject
        ).then((facet) => {
            if (!facet) {
                return;
            }
            else if (this.isValidFacet(facet)) {
                this.http.put<any>('/api/Facet/UpdateFacet', facet)
                    .subscribe({
                        next: response => {
                            console.log("updated facetKey: ", response);
                            this.initialize();
                        },
                        error: error => {
                            console.error('Save error: ', error);
                        }
                    });
            }
            else {
                this.invalidFacetAlert = true;
            }
            }).catch((error) => {
                console.error(error);
            });
    }

	isValidFacet(facet: Facet): boolean {
		if (facet.FacetName == null || facet.FacetName == "" || facet.FacetDisplayName == null || facet.FacetDisplayName == "" || facet.Image == null) {
            return false;
        }
        else {
            return true;
        }
    }

	clickedExpandTable(): any {
		this.tableExpanded = this.tableExpanded === false ? true : false;
	}
}

