import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { Analytics_DateCount } from '../../analytics_datecount/analytics_datecount';
import { Analytics_Studies } from '../../analytics_studies/analytics_studies';
import { ChartsService } from '../charts.service';
Chart.register(...registerables);

@Component({
  selector: 'app-chart-studies',
  templateUrl: './chart-studies.component.html',
  styleUrls: ['./chart-studies.component.scss']
})
export class ChartStudiesComponent implements OnInit, OnDestroy {
    workgroupKey;
    startDate;
    endDate;
    isLogarithmic;

    public studyData;
    public jobIDData: string[];
    public createdByData: string[];
    public dateCreatedData: string[];
    public animalsData: number[];
    public jobStatusData: string[];
    public isEndStateData: string[];
    public goalData: string[];

    public sum: number;

    public alreadyCreated = false;

    public chart: any;
    private colors: string[];

    dataSub;

    constructor(private elementRef: ElementRef,
        private http: HttpClient,
        private chartsService: ChartsService) {
        var observable = this.chartsService.getData();
        this.dataSub = observable.subscribe(data => {
            this.workgroupKey = data.workgroupKey;
            this.startDate = data.startDate;
            this.endDate = data.endDate;
            this.isLogarithmic = data.isLogarithmic;
            this.getData();
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy() {
        this.dataSub.unsubscribe();
    }

    createChart() {
        if (this.alreadyCreated) {
            this.chart.destroy();
        }

        let label =  "Studies";

        let htmlRef = this.elementRef.nativeElement.querySelector('#studiesChart');
        this.chart = new Chart(htmlRef, {
            type: 'bar',
            data: {
                labels: this.jobIDData,
                datasets: [{
                    label: "Animals in study",
                    data: this.animalsData,
                    borderColor: this.colors,
                    backgroundColor: this.colors,
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Animals"
                        }
                    }
                },
                plugins: {
                    legend: {
                        labels: {
                            filter: function (item, chart) {
                                return !item.text.includes("Animals in study");
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: label + ': ' + this.startDate.toLocaleDateString('en-US')
                            + ' - ' + this.endDate.toLocaleDateString('en-US'),
                        font: {
                            size: 20,
                        }
                    },
                    subtitle: {
                        display: true,
                        text: "Total studies: " + this.studyData.length + " | Total animals in studies: " + this.sum,
                        font: {
                            size: 16,
                        }
                    },
                    tooltip: {
                        callbacks: {
                            footer: (chart) => {

                                var infoString = 'Created by: ' + this.createdByData[chart[0].dataIndex];
                                infoString += '\nDate created: ' + this.dateCreatedData[chart[0].dataIndex];
                                infoString += '\nJob status: ' + this.jobStatusData[chart[0].dataIndex];
                                infoString += '\nIs end state: ' + this.isEndStateData[chart[0].dataIndex];
                                return infoString;
                            }
                        }
                    }
                }
            }
        });
        if (this.isLogarithmic) {
            this.chart.options = {
                scales: {
                    y: {
                        type: "logarithmic",
                        title: {
                            display: true,
                            text: "Animals"
                        }
                    }
                },
                plugins: {
                    legend: {
                        labels: {
                            filter: function (item, chart) {
                                return !item.text.includes("Animals in study");
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: label + ': ' + this.startDate.toLocaleDateString('en-US')
                            + ' - ' + this.endDate.toLocaleDateString('en-US'),
                        font: {
                            size: 20,
                        }
                    },
                    subtitle: {
                        display: true,
                        text: "Total studies: " + this.studyData.length + " | Total animals in studies: " + this.sum,
                        font: {
                            size: 16,
                        }
                    },
                    tooltip: {
                        callbacks: {
                            footer: (chart) => {
                                var infoString = 'Created by: ' + this.createdByData[chart[0].dataIndex];
                                infoString += '\nDate created: ' + this.dateCreatedData[chart[0].dataIndex];
                                infoString += '\nJob status: ' + this.jobStatusData[chart[0].dataIndex];
                                infoString += '\nIs end state: ' + this.isEndStateData[chart[0].dataIndex];
                                return infoString;
                            }
                        }
                    }
                }
            }
            this.chart.update();
        }
    }

    getData() {
        this.jobIDData = [];
        this.createdByData = [];
        this.dateCreatedData = [];
        this.jobStatusData = [];
        this.isEndStateData = [];
        this.animalsData = [];
        this.goalData = [];
        this.colors = [];

        const dateCount: Analytics_DateCount = {
            StartDate: this.startDate,
            EndDate: this.endDate,
            WorkgroupId: Number(this.workgroupKey),
            Table: null,
            Date: '',
            Count: 0,
        };

        this.http.post('api/Analytics/Studies', dateCount).subscribe(studies => {
            this.studyData = studies as Analytics_Studies[];

            for (let i = 0; i < this.studyData.length; i++) {
                if (this.studyData[i].animals > 0) {
                    this.jobIDData.push(this.studyData[i].jobID);
                    this.createdByData.push(this.studyData[i].createdBy);
                    this.dateCreatedData.push(this.studyData[i].dateCreated);
                    this.jobStatusData.push(this.studyData[i].jobStatus);
                    this.isEndStateData.push(this.studyData[i].isEndState);
                    this.animalsData.push(this.studyData[i].animals);
                    this.goalData.push(this.studyData[i].goal);

                    var active = '#E1EAE1';
                    var complete = '#68AA46';

                    if (!this.studyData[i].isEndState) {
                        this.colors.push(active);
                    } else {
                        this.colors.push(complete);
                    }
                }
            }

            this.getTotalSum();
            this.createChart();
            this.alreadyCreated = true;
        })
    }

    getTotalSum() {
        this.sum = 0;
        this.animalsData.forEach((totalDay) => {
            this.sum += totalDay;
        })
    }

}
