<div class="modal-header">
	<h4 class="modal-title">{{updateType}} Role</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<form>
		<div class="form-group">
			<label for="workgroupKey">Workgroup Key</label>
			<p id="workgroupKey" name="workgroupKey">{{climbRole.workgroupKey}}</p>
		</div>

		<div class="form-group">
			<label for="key">Role</label>
			<input [(ngModel)]="climbRole.roleName" required id="climbRoleName" name="climbRoleName" class="form-control" />
		</div>

		<div class="form-group">
			<label for="value">Registration Code</label>
			<div style="display: flex; align-items: center;">
				<input [(ngModel)]="climbRole.registrationCode" required id="registrationCode" name="registrationCode" class="form-control" disabled />
				<button type="button" class="btn btn-light btn-reset" (click)="generateRegistrationCode()">Reset</button>
			</div>
		</div>
</form>
	<div class="table-header">
		<button (click)="clickedExpandTable()"
				type="button" class="btn btn-link header-element"
				aria-label="Expand Facets Table"
				placement="top"
				container="body">
			<fa-icon *ngIf="!tableExpanded" icon="chevron-down" aria-hidden="true"></fa-icon>
			<fa-icon *ngIf="tableExpanded" icon="chevron-up" aria-hidden="true"></fa-icon>
		</button>
		<h2 class="header-element">
			Facets
		</h2>
	</div>
	<div class="d-flex">
		<table class='table' *ngIf="facets && tableExpanded">
			<thead>
				<tr>
					<th>
						<button type="button" class="btn btn-link"
								placement="top"
								ngbTooltip="Add"
								tooltipClass="tooltipClass"
								[openDelay]="500"
								container="body"
								(click)="clickedAddClimbRoleFacet();">
							<fa-icon icon="plus-circle" aria-hidden="true"></fa-icon>
						</button>
					</th>
					<th>Facet</th>
					<th>Read</th>
					<th>Write</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let climbRoleFacet of climbRole.climbRoleFacets">
					<ng-container *ngIf="!climbRoleFacet.delete">
						<td>
							<button type="button" class="btn btn-link"
									placement="top"
									ngbTooltip="Delete"
									tooltipClass="tooltipClass"
									[openDelay]="500"
									container="body"
									(click)="clickedDeleteClimbRoleFacet(climbRoleFacet)">
								<fa-icon icon="times" aria-hidden="true"></fa-icon>
							</button>
						</td>
						<td>
							<select [(ngModel)]="climbRoleFacet.facetKey"
									required
									id="facet_{{climbRoleFacet.climbRoleFacetKey}}"
									name="facet"
									class="form-control">
								<option *ngFor="let facet of facets" [ngValue]="facet.facetKey">{{facet.facetName}}</option>
							</select>
						</td>
						<td>
							<input type="checkbox"
								   [(ngModel)]="climbRoleFacet.hasReadAccess"
								   name="readAccess" />
						</td>
						<td>
							<input type="checkbox"
								   [(ngModel)]="climbRoleFacet.hasWriteAccess"
								   name="writeAccess" />
						</td>
					</ng-container>
				</tr>
			</tbody>
		</table>
	</div>
	<div>
		<ngb-alert *ngIf="emptyRole" type="warning" dismissible="true" (close)="emptyRole=false">
			Save failed: role name cannot be blank.
		</ngb-alert>
		<ngb-alert *ngIf="emptyRegCode" type="warning" dismissible="true" (close)="emptyRegCode=false">
			Save failed: registration code cannot be blank.
		</ngb-alert>
		<ngb-alert *ngIf="invalidRegCode" type="warning" dismissible="true" (close)="invalidRegCode=false">
			Save failed: registration code must be unique. Please, reset the code.
		</ngb-alert>
		<ngb-alert *ngIf="invalidFacetAlert" type="warning" dismissible="true" (close)="invalidFacetAlert=false">
			Save failed: facet selection cannot be blank.
		</ngb-alert>
	</div>
</div>
<div class="modal-footer">
	<button type="button"
			class="btn btn-primary"
			(click)="onSave()">
		Save
	</button>
	<button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
</div>