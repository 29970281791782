import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { ChartDatecountComponent } from './chart-datecount.component';
import { ChartStudiesModule } from './chart-studies/chart-studies.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        ChartStudiesModule,
    ],
    declarations: [
        ChartDatecountComponent,
    ],
    exports: [
        ChartDatecountComponent,
    ],
    providers: [
    ],
})
export class ChartDatecountModule { }