import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Workgroup } from '../workgroup/workgroup';
import { ViewEncapsulation } from '@angular/core';
import { Analytics_DateCount } from '../analytics_datecount/analytics_datecount';

@Component({
    selector: 'workgroup-details',
    templateUrl: './workgroup-details.component.html',
    styleUrls: ['./workgroup-details.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class WorkgroupDetailsComponent implements OnInit {
    workgroup: Workgroup; // workgroup object
    timeZones: any[] = [];
    chartData: Analytics_DateCount[] = [];

    constructor(
        private http: HttpClient,
        private route: ActivatedRoute
    ) {
        // Do nothing
    }

    ngOnInit() {
        const workgroupKey = this.route.snapshot.paramMap.get('id');

        this.http.get(`/api/Workgroup/WorkgroupDetailsByKey/${workgroupKey}`).subscribe(result => {
            this.workgroup = result as Workgroup;
        });
        this.http.get(`/api/Workgroup/GetTimeZoneInfo`).subscribe(tz => {
            this.timeZones = tz as any[];
        });
    }

    updateWorkgroup() {
        this.http.put<any>('/api/Workgroup/UpdateWorkgroup', this.workgroup)
            .subscribe({
                next: response => {
                    console.log("updated workgroupKey: ", response);
                },
                error: error => {
                    console.error('Save error: ', error);
                }
            });
    }
}

