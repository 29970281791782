import { Component, OnInit } from '@angular/core';
// import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Workgroup } from '../workgroup/workgroup';
import { User } from './user';
import { Role } from '../role/role';
import { RoutingService } from '../routing/routing.service';
import { ConfirmService } from '../common/confirm/confirm.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
    public users: User[];
    public workgroups: Workgroup[];
    public roles: Role[];
    selectedUser: any;
    selectedRole: any;
    selectedWorkgroup: any;

    constructor(
        private http: HttpClient,
        private routingService: RoutingService,
        private confirmService: ConfirmService
    ) {
    }

    ngOnInit() {
        this.http.get("/api/User/Users").subscribe(result => {
            this.users = result as User[];
        });
    }

    async navigateToUserDetails(userKey: number): Promise<void> {
        // Ensure active element is not the delete button
        // Needs to be wrapped in a timeout to make sure it's checked on next Angular lifecycle tick after focusing
        const activeElement: Element = await new Promise(resolve => {
            setTimeout(() => resolve(document.activeElement));
        });

        if (!activeElement.classList.contains("btn-danger")) {
            this.routingService.navigateToUserDetailsWithKey(userKey);
        }
    }

    async clickedDeleteUser(user: User) {
        await this.confirmService.confirmDelete("Delete User", "Are you sure you want to permanently delete this user?").then(
            // Delete confirmed
            () => {
                this.http.delete(`/api/User/DeleteUser/${user.key}`).subscribe(result => {
                    this.users = this.users.filter(u => u.key !== user.key);
                });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }
}

