import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { JobComponent } from './job.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        JobComponent
    ],
    exports: [
        JobComponent
    ],
    providers: [
    ]
})
export class JobModule { }
