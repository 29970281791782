import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfirmService } from '../common/confirm/confirm.service';
import { FeatureFlag } from './feature-flag';

@Component({
    selector: 'feature-flags',
    templateUrl: './feature-flags.component.html',
    styleUrls: ['./feature-flags.component.css']
})
export class FeatureFlagsComponent implements OnInit {
    public flags: FeatureFlag[];
    selectedFlag: any;

    constructor(
        private http: HttpClient,
        private confirmService: ConfirmService
    ) {
    }

    ngOnInit() {
        // TODO: Replace with API call when flag descriptions are added to db/api
        this.flags = [
            {
                key: "CohortSplittingMethods",
                description: "Used to enable cohort splitting."
            },
            {
                key: "CPI_URI",
                description: "The URI used for connecting to SAP Cloud Platform Integration."
            },
            {
                key: "CPI_X509",
                description: "The X.509 authentication certificate used for connecting to SAP Cloud Platform Integration."
            },
            {
                key: "DotmaticsConfig",
                description: "The configuration object used for connecting to Dotmatics services."
            },
            {
                key: "Enforce_SSO",
                description: "Used to require sign-in via SSO instead of username/password (duplicate)."
            },
            {
                key: "EnforceSSO",
                description: "Used to require sign-in via SSO instead of username/password."
            },
            {
                key: "FifteenMinutesScheduling",
                description: "Used to change cell duration from 30 to 15 minutes in Schedule facet calendar."
            },
            {
                key: "IP_Whitelist",
                description: "Used to limit the users returned by the WorkgroupUsers endpoint to only those with whitelisted IP addresses."
            },
            {
                key: "IsClassicJobOnly",
                description: "Used to prevent setting task status of all other tasks to which an animal belongs to default end state status when a task's status is set to end state."
            },
            {
                key: "IsCRL",
                description: "Used to enable features specific to Charles River Laboratories such as standard phrase categories and requirements, altered institution metadata, and various Jobs Pharma enhancements like the Edit Task Modal."
            },
            {
                key: "IsCRO",
                description: "Used to enable features specific to Contract Research Organizations such as additional institution metadata, job characteristic types and subtypes, cohort name editing, animal placeholders, various enhancements to protocol tasks within a study, and additional reporting options."
            },
            {
                key: "IsDotmatics",
                description: "Used to enable Dotmatics integration."
            },
            {
                key: "IsGLP",
                description: "Used to designate a workgroup as GLP (Good Laboratory Practice) compliant, thereby enabling features such as enforcing reasons for change when editing entities, inactivity timeouts, and alternate physical IDs for animals."
            },
            {
                key: "IsSandbox",
                description: "Used to modify chart colors in the dashboard."
            },
            {
                key: "PINGID_PASSWORD",
                description: "The password used to connect to CRL customer services."
            },
            {
                key: "PINGID_URI",
                description: "The URI used to connect to CRL customer services."
            },
            {
                key: "PINGID_USERNAME",
                description: "The username used to connect to CRL customer services."
            },
            {
                key: "ShowEditPostCompletedModal",
                description: "Used to control whether a modal is displayed when editing a task in Workflow Bulk Data Entry that is in an end state or has been changed from an end state to in progress."
            },
            {
                key: "UseJobsClassic",
                description: "Used to indicate that Studies 1.0 are active and enable the visibility of output options in the Details view of the Tasks facet."
            },
            { key: "", description: "" },
        ];
    }

    // TODO: Add create/edit functionality when flag descriptions are added to db/api

    // TODO: Re-enable delete functionality when flag descriptions are added to db/api
    //async clickedDeleteFlag(flag: FeatureFlag) {
    //    await this.confirmService.confirmDelete("Delete Flag", "Are you sure you want to permanently delete this feature flag?").then(
    //        // Delete confirmed
    //        () => {
    //            this.http.delete(`/api/User/DeleteUser/${flag.key}`).subscribe(result => {
    //                this.flags = this.flags.filter(f => f.key !== flag.key);
    //            });
    //        },
    //        // Cancel
    //        () => { /* do nothing on cancel */ }
    //    );
    //}
}

