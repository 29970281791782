import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm-modal.component';
import { ConfirmOptions } from './confirm-options';


@Injectable()
export class ConfirmService {

    modalOptions: NgbModalOptions;

    constructor(
        private modalService: NgbModal
    ) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }

    confirm(confirmOptions: ConfirmOptions): Promise<any> {
        // Open modal
        const modalRef = this.modalService.open(ConfirmModalComponent, this.modalOptions);
        let component = modalRef.componentInstance as ConfirmModalComponent;

        // Set modal's ConfirmOptions
        // assign defaults
        let defaultOptions = {
            yesButtonText: 'OK',
            noButtonText: 'Cancel',
            isDanger: false,
        };
        Object.assign(defaultOptions, confirmOptions);
        confirmOptions = <ConfirmOptions>defaultOptions;

        confirmOptions.yesButtonClass = this.getYesButtonClass(confirmOptions.isDanger);
        component.options = confirmOptions;

        return modalRef.result;
    }

    confirmDelete(title: string, message: string): Promise<any> {
        let yesButtonText = "Delete";
        let noButtonText = "Cancel";
        let isDanger = true;
        let confirmOptions: ConfirmOptions = {
            title,
            message,
            yesButtonText,
            noButtonText,
            isDanger,
        };
        return this.confirm(confirmOptions);
    }

    private getYesButtonClass(isDanger: boolean): string {
        if (isDanger) {
            return 'btn-danger';
        } else {
            return 'btn-primary';
        }
    }
}
