<div class="p-2">
    <h1>Users</h1>
</div>
<p *ngIf="!users"><em>Loading...</em></p>
<table class='table' *ngIf="users">
    <thead>
        <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>User Name</th>
            <th>Workgroups</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let user of users" (click)="navigateToUserDetails(user.key)">
            <td>
                <button type="button" class="btn btn-link"
                        placement="top"
                        ngbTooltip="Delete"
                        tooltipClass="tooltipClass"
                        [openDelay]="500"
                        container="body"
                        (click)="clickedDeleteUser(user);">
                    <fa-icon icon="times" aria-hidden="true"></fa-icon>
                </button>
            </td>
            <td>{{user.firstName}}</td>
            <td>{{user.lastName}}</td>
            <td>{{user.userName}}</td>
            <td>
                <div *ngFor="let workgroup of user.workgroups">
                    {{workgroup.name}}
                </div>
            </td>
        </tr>
    </tbody>
</table>



