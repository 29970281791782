export class Job {
    public ID: string;
    public name: string;
    public command: string;
    public targetGroup: string;
    public status: string;
    public startTime: Date;
    public endTime: Date;
    public errorMessage: string | null;

    constructor() { }
}

export class JobDetail {
    public jobName: string;
    public server: string;
    public database: string;
    public elasticPool: string;

    public status: string;
    public lastMessage: string;
    public startTime: Date;
    public endTime: Date;

    constructor() { }
}

export class JobTargets {
    public groups: string[];
    public elasticPools: string[];
    public databases: string[];

    constructor() { }
}