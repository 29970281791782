import { Injectable } from '@angular/core';
import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { AddToWorkgroupModalComponent } from './add-to-workgroup-modal.component';


@Injectable()
export class AddToWorkgroupModalService {
    modalOptions: NgbModalOptions;

    constructor(private modalService: NgbModal) {
        this.init();
    }

    private init() {
        // NgbModalOptions: https://ng-bootstrap.github.io/#/components/modal/api#NgbModalOptions
        this.modalOptions = {
            size: 'lg',
            backdrop: 'static'
        };
    }

    openAddToWorkgroupModal(): Promise<any> {
        // Open modal
        const modalRef = this.modalService.open(
            AddToWorkgroupModalComponent, this.modalOptions
        );
        const component = modalRef.componentInstance as AddToWorkgroupModalComponent;        

        return modalRef.result.then(result => {
            return {
                workgroupKey: component.selectedWorkgroup.key,
                roleName: component.selectedRole.roleName
            };
        }, reason => {
            return reason;
        });
    }
}
