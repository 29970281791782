import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ClimbRole } from '../../../climb-roles/climb-roles-models';
import { Workgroup } from '../../../workgroup/workgroup';

@Component({
    selector: 'app-add-to-workgroup-modal',
    templateUrl: './add-to-workgroup-modal.component.html',
    styleUrls: ["./add-to-workgroup-modal.component.css"],
})
export class AddToWorkgroupModalComponent implements OnInit {
    modalOptions: NgbModalOptions;
    workgroups: Workgroup[];
    roles: ClimbRole[];
    selectedWorkgroup: Workgroup;
    selectedRole: ClimbRole;

	constructor(
		public activeModal: NgbActiveModal,
		private http: HttpClient
	) {}

    ngOnInit() {
        this.fetchWorkgroups();
    }

    fetchWorkgroups() {
        this.http.get<Workgroup[]>("/api/Workgroup/Workgroups").subscribe(result => {
            this.workgroups = result;
            this.selectedWorkgroup = this.workgroups[0];
            this.fetchRoles();
        });
    }

    fetchRoles() {
        this.http.get<ClimbRole[]>(`/api/ClimbRole/ClimbRolesByWorkgroupKey/${this.selectedWorkgroup.key}`).subscribe(result => {
            this.roles = result;
            this.selectedRole = this.roles[0];
        });
    }

    workgroupChanged(workgroup: Workgroup): void {
        this.fetchRoles();
    }
}
