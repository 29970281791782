import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FeatureFlagsComponent } from './feature-flags.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        NgbModule
    ],
    declarations: [
        FeatureFlagsComponent
    ],
    exports: [
        FeatureFlagsComponent
    ],
    providers: [
    ]
})
export class FeatureFlagsModule { }
