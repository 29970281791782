import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { UserComponent } from './user.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        NgbModule
    ],
    declarations: [
        UserComponent
    ],
    exports: [
        UserComponent
    ],
    providers: [
    ]
})
export class UserModule { }
