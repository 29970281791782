<div class="modal-header">
    <h4 class="modal-title">{{updateType}} Facet</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form>
        <div class="form-group">
            <label for="workgroupKey">Workgroup Key</label>
            <p id="workgroupKey" name="workgroupKey">{{facet.WorkgroupKey}}</p>
        </div>

        <div class="form-group">
            <label for="key">Facet</label>
            <!--<input [(ngModel)]="facet.FacetName" required id="facetName" name="facetName" class="form-control" />-->
			<select [(ngModel)]="facet.FacetName"
					required
					id="facet"
					name="facet"
					class="form-control">
				<option *ngFor="let facetName of facetNames" [ngValue]="facetName">{{facetName}}</option>
			</select>
        </div>

        <div class="form-group">
            <label for="value">Display Name</label>
            <input [(ngModel)]="facet.FacetDisplayName" required id="displayName" name="displayName" class="form-control" />
        </div>

		<div class="form-group">
			<label for="key">Image</label>
			<input [(ngModel)]="facet.Image" required id="image" name="image" class="form-control" />
		</div>

    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary"
            (click)="activeModal.close('save')">
        Save
    </button>

    <button type="button" class="btn btn-light" (click)="activeModal.dismiss()">Cancel</button>
</div>