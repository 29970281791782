<div class="p-2">
    <h1>Usage Reports</h1>
</div>
<div class="body">
    <p class="description">Generate an exportable report of <b>total unique logins per day</b> in each workgroup over the specified date range.
        Click on a row to see a breakdown by user.</p>
    <form class="col-xs-12 d-flex justify-content-between align-items-center" (ngSubmit)="onSubmit()" #f="ngForm" id=form>
        <div class="d-flex align-items-center">
            <div class="form-group">
                <mat-form-field>
                    <mat-label>Enter a date range</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                        <input matStartDate formControlName="start" placeholder="Start date" />
                        <input matEndDate formControlName="end" placeholder="End date" />
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                </mat-form-field>
                <mat-error *ngIf="this.invalidDate" style="margin-top: 8px;">Please enter a start and end date</mat-error>
            </div>
            <button class="btn btn-primary" type="submit">View report</button>
        </div>
        <div>
            <button class="btn btn-secondary" type="button" *ngIf="loaded" (click)="downloadWgLoginData(workgroupsData, wgHeaders)">Download csv</button>
        </div>
    </form>
    <p *ngIf="submitted && !loaded">Loading...</p>
    <table *ngIf="loaded" class="table table-hover">
        <thead>
            <tr>
                <th>Workgroup name</th>
                <th>Key</th>
                <th>Total logins</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let workgroup of workgroups; index as i"
                (click)="workgroupsData[i].detailsHidden = !workgroupsData[i].detailsHidden; getUsernameBreakdown(startDate, endDate, workgroup.key)">
                <td>{{ workgroupsData[i].name }}
                    <div *ngIf="!workgroupsData[i].detailsHidden && workgroupsData[i].detailsLoaded" class="list-group">
                        <div *ngFor="let user of workgroupsData[i].userData; index as j">
                            <p><b>{{ workgroupsData[i].userData[j].Username }}</b> logged in on <b>{{ workgroupsData[i].userData[j].Logins }}</b> day(s)</p>
                        </div>
                        <button class="btn btn-light" (click)="exportToCsv(workgroupsData[i].userData, userHeaders)" type="button">Download csv</button>
                    </div>
                </td>
                <td>{{ workgroupsData[i].key }}</td>
                <td>{{ workgroupsData[i].logins }}</td>
            </tr>
        </tbody>
    </table>
</div>