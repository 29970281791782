import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { WorkgroupComponent } from './workgroup.component';
import { RoutingSharedModule } from '../routing/routing-shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        RoutingSharedModule
    ],
    declarations: [
        WorkgroupComponent
    ],
    exports: [
        WorkgroupComponent
    ],
    providers: [
    ]
})
export class WorkgroupModule { }
