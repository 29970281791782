import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClimbRole, ClimbRoleFacet } from '../climb-roles-models';
import {
    Component,
    Input,
    OnInit,
    EventEmitter,
    Output,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RegistrationCodeService } from '../../common/registration-code.service';

@Component({
    selector: 'update-climb-role-modal',
	templateUrl: './update-climb-role-modal.component.html',
	styleUrls: ['./update-climb-role-modal.component.css']
})
export class UpdateClimbRoleModalComponent implements OnInit {
	@Input() climbRole: ClimbRole;
	@Input() workgroupKey: number;
	@Output() facetsAdded: EventEmitter<any> = new EventEmitter<any>();
	@Output() facetsEdited: EventEmitter<any> = new EventEmitter<any>();

	updateType: string;
	facetsAddedList: any[] = [];
	facetsEditedList: any[] = [];

	climbRoleFacets: any;
	facets: any;
	emptyRole: boolean = false;
	emptyRegCode: boolean = false;
	invalidRegCode: boolean = false;
	invalidFacetAlert: boolean = false;
	tableExpanded: boolean = false;


    constructor(
		public activeModal: NgbActiveModal,
		private http: HttpClient,
		private registrationCodeService: RegistrationCodeService
    ) { }

    ngOnInit() {
        this.initialize();
    }

    initialize() {
		this.updateType = this.climbRole.climbRoleKey < 0 ? "Add" : "Edit"

		if (this.updateType == "Add") {
			this.generateRegistrationCode();
		}

		// Get data for table
		this.invalidFacetAlert = false;
		this.http.get(`/api/ClimbRoleFacet/ClimbRoleFacetsByClimbRoleKey/${this.climbRole.climbRoleKey.toString()}`).subscribe(result => {
			this.climbRole.climbRoleFacets = result;
		});
		this.http.get(`/api/Facet/FacetsByWorkgroupKey/${this.workgroupKey.toString()}`).subscribe(result => {
			this.facets = result;
		});
	}

	async onSave() {
		if (await this.isSubmissionValid()) {
			this.activeModal.close('save');
		}
	}

	clickedAddClimbRoleFacet() {
		var climbRoleFacetObject: ClimbRoleFacet = {
			climbRoleFacetKey: -1,
			climbRoleKey: this.climbRole.climbRoleKey,
			facetKey: null,
			hasReadAccess: true,
			hasWriteAccess: false,
			delete: false
		}

		// Add new facet to the beginning of the array
		this.climbRole.climbRoleFacets.unshift(climbRoleFacetObject);
		this.facetsAddedList.push(climbRoleFacetObject);
	}

	clickedDeleteClimbRoleFacet(climbRoleFacet: any) {
		if (climbRoleFacet.facetKey) {
			climbRoleFacet.delete = true;
		} else {
			// The record hasn't been created yet, so remove it from the array
			let index = this.climbRole.climbRoleFacets.indexOf(climbRoleFacet);
			this.climbRole.climbRoleFacets.splice(index, 1);
		}
    }

	async isSubmissionValid(): Promise<boolean> {
		if (!this.climbRole.roleName || this.climbRole.roleName.trim().length === 0) {
			this.emptyRole = true;
			return false;
		}
		this.emptyRole = false;

		if (!this.climbRole.registrationCode || this.climbRole.registrationCode.trim().length === 0) {
			this.emptyRegCode = true;
			return false;
		}
		this.emptyRegCode = false;

		if (!(await this.isRegistrationCodeUnique(this.climbRole.registrationCode))) {
			this.invalidRegCode = true;
			return false;
		}
		this.invalidRegCode = false;

		for (let facet of this.climbRole.climbRoleFacets) {
			if (!facet.facetKey) {
				this.invalidFacetAlert = true;
				return false;
			}
		}
		this.invalidFacetAlert = false;

		return true;
	}

	generateRegistrationCode() {
		this.registrationCodeService.generateRoleRegistrationCode().then(regCode => {
			this.climbRole.registrationCode = regCode;
			this.invalidRegCode = false;
		});
	}

	isRegistrationCodeUnique(regCode: string): Promise<boolean> {
		return this.registrationCodeService.isRoleRegistrationCodeUnique(this.climbRole.climbRoleKey, regCode);
    }

	clickedExpandTable(): any {
		this.tableExpanded = this.tableExpanded === false ? true : false;
	}
}
